import React, { useState, useEffect } from 'react';
import { CButton, CCard, CCardBody, CCol, CRow, CCollapse, CBadge, CInput, CLabel, CSelect, CFormGroup, CProgress, CProgressBar, CTextarea, CAlert } from '@coreui/react';
import ModalComments from 'src/reusable/modal-comment/ModalComments';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import './ProjectDetails.css';
import { useSelector } from 'react-redux';
import axios from 'axios';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import IP from '../../StoreGlobal/IP';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { FaTrashAlt } from 'react-icons/fa';

import { getTwoWeeksFilter, getTwoWeeksData, saveWeekly } from 'src/request/Reports';
import Lottie from 'react-lottie';
import animationData from '../../json/confetti2.json';
import $axios from 'src/utils/axios';

const HistoricalReports = (props) => {
  const [toggle, setToggle] = useState([]);
  const [weeklyReport, setWeeklyReport] = useState([]);
  const [date, setDate] = useState('');
  const [baseDate, setBaseDate] = useState('');
  const [weeks, setWeeks] = useState([]);
  const [projectId, setprojectId] = useState('');
  const [projectStart, setprojectStart] = useState('');
  const [projectEnd, setprojectEnd] = useState('');
  let accessToken = localStorage.getItem('token');
  const [missedDates, setMissedDates] = useState(1);
  const [datesList, setDatesList] = useState([]);
  const [isModalCommentOpen, setIsModalCommentOpen] = useState(false);
  const [reportData, setReportData] = useState([]);
  useEffect(() => {
    // getWeeks(props.id);
    // console.log('missedDates', props.missedDates[0]);
    // setDate(props.missedDates[0].toString());
    setprojectId(props.id);
    dateformat(props.start, props.end);
    getDatesList();
  }, []);

  const openModalComment = (item) => {
    setReportData(item);
    setIsModalCommentOpen(true);
  };
  const closeModalComment = () => {
    setIsModalCommentOpen(false);
    setReportData([]);
  };
  useEffect(() => {
    if (datesList.length > 0) {
      const firstDateRange = `${datesList[0].start_date} - ${datesList[0].end_date}`;
      getWeeklyData(firstDateRange);
    }
  }, [datesList]);

  // const getWeeks = (project_id) => {
  //   getTwoWeeksFilter(project_id).then((res) => {
  //     console.log(res, 'am hir');
  //     if (res.data.status == 200) {
  //       setWeeks(res.data.response);
  //       setDate(`${res.data.response[0].start_date} - ${res.data.response[0].end_date} `);
  //       setBaseDate(`${res.data.response[0].start_date} - ${res.data.response[0].end_date} `);
  //       getTwoWeeks(`${res.data.response[0].start_date} - ${res.data.response[0].end_date} `);
  //     } else {
  //       Swal.fire({
  //         icon: 'error',
  //         title: 'Error',
  //         text: `${res.data.response}. Please adjust start date or duration in Overview tab.`,
  //       });
  //     }
  //   });
  // };

  const getDatesList = () => {
    $axios
      .get(`report/historical-dates/${props.id}`)
      .then((res) => {
        console.log('getDatesList', res.data);
        if (res.data.status === 200) {
          const dates = res.data.response;
          setDatesList(dates);
        }
      })
      .catch((error) => {
        console.error('Error getting dates list:', error);
      });
  };

  const getWeeklyData = (value) => {
    setDate(value);
    let date = value.split(' - ');
    $axios.get(`report/historical-report/${props.id}?start=${date[0]}&end=${date[1]}`).then((res) => {
      console.log('getWeeklyData', res.data);
      setWeeklyReport(res.data.response.data);
    });
  };

  const setDefaultDate = () => {
    setDate(baseDate);
  };

  // const saveChanges = () => {
  //   // alert()
  //   let arr = [...weeklyReport];
  //   let newArr = [];

  //   for (let i = 0; i < arr.length; i++) {
  //     let newItem = [];
  //     console.log(arr[i]);
  //     console.log(arr[i].item);
  //     arr[i].date_range = date;
  //     arr[i].save_type = 'CW';
  //     for (let j = 0; j < arr[i].item.length; j++) {
  //       if (arr[i].item[j].edited) {
  //         arr[i].item[j].start_date = adjustDateFormat(arr[i].item[j].start_date);
  //         arr[i].item[j].end_date = adjustDateFormat(arr[i].item[j].end_date);
  //         arr[i].item[j].actual_start_date = adjustDateFormat(arr[i].item[j].actual_start_date);
  //         arr[i].item[j].actual_end_date = adjustDateFormat(arr[i].item[j].actual_end_date);
  //         arr[i].item[j].date_created = adjustDateFormat(arr[i].item[j].date_created);
  //         newItem.push(arr[i].item[j]);
  //       }
  //     }

  //     if (newItem.length > 0) {
  //       arr[i].item = newItem;
  //       newArr.push(arr[i]);
  //     }
  //   }

  //   if (newArr.length > 0) {
  //     saveWeekly(props.id, newArr).then((res) => {
  //       console.log(res);
  //       if (res.data.status == 200) {
  //         Swal.fire({
  //           // position: 'top-end',
  //           icon: 'success',
  //           title: 'Your work has been saved!',
  //           showConfirmButton: false,
  //           timer: 1500,
  //         });
  //         getWeeklyData(date);
  //       } else {
  //         Swal.fire({
  //           icon: 'error',
  //           title: 'Error',
  //           text: res.data.message,
  //           showConfirmButton: false,
  //           timer: 2500,
  //         });
  //       }
  //     });
  //   } else {
  //     Swal.fire({
  //       icon: 'error',
  //       title: 'Oops..',
  //       text: 'No data to be save',
  //       confirmButtonColor: '#23303D',
  //       // footer: '<a href="">Why do I have this issue?</a>',
  //     });
  //   }
  // };

  const saveChanges = () => {
    let arr = [...weeklyReport];
    let newArr = [];

    for (let i = 0; i < arr.length; i++) {
      let newItem = [];
      console.log(arr[i]);
      console.log(arr[i].item);
      arr[i].date_range = date;
      arr[i].save_type = 'CW';
      for (let j = 0; j < arr[i].item.length; j++) {
        if (arr[i].item[j].edited) {
          // arr[i].item[j].actual_date = adjustDateFormat(arr[i].item[j].actual_date);
          // arr[i].item[j].target_date = adjustDateFormat(arr[i].item[j].target_date);
          arr[i].item[j].start_date = adjustDateFormat(arr[i].item[j].start_date);
          arr[i].item[j].end_date = adjustDateFormat(arr[i].item[j].end_date);
          arr[i].item[j].actual_start_date = adjustDateFormat(arr[i].item[j].actual_start_date);
          arr[i].item[j].actual_end_date = adjustDateFormat(arr[i].item[j].actual_end_date);
          arr[i].item[j].date_created = adjustDateFormat(arr[i].item[j].date_created);
          newItem.push(arr[i].item[j]);
        }
      }

      if (newItem.length > 0) {
        arr[i].item = newItem;
        newArr.push(arr[i]);
      }
    }

    // arr.forEach((data) => {
    //   let newData = data.item.find(
    //     (a) => a["new"] == true || a["edited"] == true
    //   );
    //   if (typeof newData != "undefined") {
    //     newArr.push(data);
    //   }

    //   // let dataArr = data.item;
    //   // console.log(data.item);
    //   // let newData = dataArr.find(
    //   //   (a) => a["new"] == true || a["edited"] == true
    //   // );
    //   // if (typeof newData != "undefined") {
    //   //   newArr.push(newData);
    //   // }
    // });

    if (newArr.length > 0) {
      saveWeekly(props.id, newArr).then((res) => {
        console.log(res);
        if (res.data.status == 200) {
          Swal.fire({
            // position: 'top-end',
            icon: 'success',
            title: 'Your work has been saved!',
            showConfirmButton: false,
            timer: 1500,
          });
          getWeeklyData(date);
          getDatesList();
          props.getTabWarnings(props.id);
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: res.data.message,
            showConfirmButton: false,
            timer: 2500,
          });
        }
      });
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Oops..',
        text: 'No data to be save',
        confirmButtonColor: '#23303D',
        // footer: '<a href="">Why do I have this issue?</a>',
      });
    }
  };

  const AddNew = (Index) => {
    let arr = [...weeklyReport];
    console.log(arr);

    arr[Index].item.push({
      psow_id: null,
      report_id: null,
      phase_id: null,
      sow_id: null,
      work_item: '',
      target_date: '',
      actual_date: '',
      comments: '',
      new: true,
    });
    setWeeklyReport(arr);
  };

  const toggleShow = (value) => {
    const shownState = toggle.slice();
    const index = shownState.indexOf(value);

    if (index >= 0) {
      shownState.splice(index, 1);
      setToggle(shownState);
    } else {
      shownState.push(value);
      setToggle(shownState);
    }
  };

  const workItemChange = (e, dataIndex, itemIndex) => {
    let arr = [...weeklyReport];
    arr[dataIndex].item[itemIndex].work_item = e.target.value;
    setWeeklyReport(arr);
  };

  const adjustDateFormat = (strDate) => {
    if (strDate) {
      let a = new Date(strDate);
      let sm = `${a.getMonth() + 1}`.padStart(2, 0);
      let sd = `${a.getDate()}`.padStart(2, 0);
      let sy = a.getFullYear();
      let formatted = `${sm}/${sd}/${sy}`;
      return formatted;
    }

    return '';
  };

  const targetDateChange = (e, dataIndex, itemIndex) => {
    let arr = [...weeklyReport];
    arr[dataIndex].item[itemIndex].target_date = e.target.value;
    arr[dataIndex].item[itemIndex].edited = true;
    setWeeklyReport(arr);
  };

  const commentChange = (e, dataIndex, itemIndex) => {
    let arr = [...weeklyReport];
    arr[dataIndex].item[itemIndex].comments = e.target.value;
    arr[dataIndex].item[itemIndex]['edited'] = true;
    setWeeklyReport(arr);
  };

  const actualDateChange = (e, dataIndex, itemIndex) => {
    let arr = [...weeklyReport];
    arr[dataIndex].item[itemIndex].actual_date = e.target.value;
    arr[dataIndex].item[itemIndex]['edited'] = true;
    setWeeklyReport(arr);
  };

  const remove = (dataIndex, itemIndex) => {
    let arr = [...weeklyReport];

    arr[dataIndex].item.splice(itemIndex, 1);
    setWeeklyReport(arr);
  };

  const dateformat = (start, end) => {
    let a = new Date(start);
    let b = new Date(end);

    let sm = a.getMonth() + 1;
    let sd = a.getDate();
    let sy = a.getFullYear();
    let date_start = sy + '-' + sm + '-' + sd;

    let em = b.getMonth() + 1;
    let ed = b.getDate();
    let ey = b.getFullYear();
    let date_end = ey + '-' + em + '-' + ed;

    setprojectStart(date_start.toString());
    setprojectEnd(date_end.toString());
  };

  const autosave = (data, Index, scopeIndex) => {
    axios
      .post(IP + 'sow/save', {
        data,
        // company_id: company_id,
        headers: {
          'Content-Type': 'appliation/json',
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((res) => {
        if (typeof data.dependencies != 'undefined') {
          let arr = [...weeklyReport];
          arr[scopeIndex].Task[Index].Dependencies.push(...data.dependencies);

          setWeeklyReport(arr);
        }
      })
      .catch((error) => {
        console.log('projectdetails/ProjectOverview.js', ' ', 'line 318', error);
      });
  };

  const changeSowProgress = (value, Index, dataIndex) => {
    const newArr = [...weeklyReport];
    newArr[dataIndex].item[Index].status = value;
    newArr[dataIndex].item[Index].edited = true;
    setWeeklyReport(newArr);
  };

  const changestartdate = (value, Index, dataIndex, category) => {
    const newArr = [...weeklyReport];
    if (category === 'target') {
      newArr[dataIndex].item[Index].StartDate = value;
    } else if (category === 'actual') {
      newArr[dataIndex].item[Index].actual_start_date = value;
    }
    newArr[dataIndex].item[Index].edited = true;

    setWeeklyReport(newArr);
  };

  const changeenddate = (value, Index, dataIndex, category) => {
    const newArr = [...weeklyReport];
    if (category === 'target') {
      newArr[dataIndex].item[Index].EndDate = value;
    } else if (category === 'actual') {
      newArr[dataIndex].item[Index].actual_end_date = value;
    }

    newArr[dataIndex].item[Index].edited = true;
    setWeeklyReport(newArr);
  };

  const ItemBadge = ({ underBudget, delayed, incomplete }) => {
    let badge = [];

    if (underBudget == 1) {
      badge.push(
        <CBadge color="aimhi-success" className="badge-stats" style={{ marginTop: '15px', width: '150px' }}>
          WITHIN BUDGET
        </CBadge>,
      );
    } else {
      badge.push(
        <CBadge color="aimhi-danger" className="badge-stats" style={{ marginTop: '15px', width: '150px' }}>
          OVER BUDGET
        </CBadge>,
      );
    }

    if (incomplete == 1) {
      badge.push(
        <CBadge color="aimhi-success" className="badge-stats" style={{ marginTop: '15px', width: '150px' }}>
          INCOMPLETE
        </CBadge>,
      );
    } else {
      if (delayed == 0) {
        badge.push(
          <CBadge color="aimhi-success" className="badge-stats" style={{ marginTop: '3px', width: '150px' }}>
            ON-TIME
          </CBadge>,
        );
      } else {
        badge.push(
          <CBadge color="aimhi-danger" className="badge-stats" style={{ marginTop: '3px', width: '150px' }}>
            DELAYED
          </CBadge>,
        );
      }
    }

    return badge;
  };

  const WeeklyCard = weeklyReport.map((data, index) => {
    let dataIndex = index;
    let ontime = true;
    const collapseCard = data.item.map((item, innerIndex) => {
      console.log('weekly card data: ', data);
      console.log('data.Total_Cost', data.total_cost);
      // let itemStatus = 50;
      let itemStatus = item.status;
      let colorstatus = '';
      if (itemStatus >= '100') {
        colorstatus = 'green';
      } else if (itemStatus >= '1' && itemStatus <= '99') {
        colorstatus = 'blue';
      } else if (itemStatus <= '0') {
        colorstatus = 'gray';
      }

      if (item.actual_end_date > item.end_date) {
        ontime = false;
      } else {
        ontime = true;
      }

      return (
        <CCard className="shadow report-history-card" style={{ marginBottom: 0, marginLeft: '40px' }} key={innerIndex}>
          <CCardBody style={{ paddingTop: 0, paddingBottom: 0, paddingLeft: 15 }}>
            <CRow>
              <div className="left-design-report float-left"></div>
              {item.new == true ? (
                <CCol lg="4" style={{ padding: '10px' }}>
                  <span style={{ fontSize: '10px', fontWeight: 'bold' }}>Work Item / Others</span>
                  <CInput value={item.work_item} onChange={(e) => workItemChange(e, dataIndex, innerIndex)} />
                </CCol>
              ) : (
                <CCol lg="4" style={{ padding: '10px' }}>
                  <span className="report-label">{item.work_item}</span>
                </CCol>
              )}

              <CCol lg="1.5" style={{ padding: '10px' }} id="Status">
                <CCol style={{ textAlign: 'center' }}>
                  <span style={{ fontSize: '10px', fontWeight: 'bold' }}>Status</span>
                </CCol>
                <div className="progress-dropdown">
                  <div className="progress-dropdown" style={{ marginTop: '3px' }}>
                    <div style={{ position: 'relative' }}>
                      <CProgress style={{ height: '15px', width: '6rem' }}>
                        <div
                          id="editable"
                          style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            color: 'black',
                            fontWeight: 'bold',
                          }}
                          onDoubleClick={(e) => {
                            if (item.is_editable) {
                              e.target.contentEditable = true;
                              e.target.innerText = e.target.innerText.replace('%', '');
                              e.target.focus();
                              e.target.style.paddingLeft = '1rem';
                              e.target.style.paddingRight = '1rem';
                            }
                          }}
                          onKeyDown={(e) => {
                            if (e.key == 'Enter') {
                              e.target.blur();
                            }
                          }}
                          onBlur={(e) => {
                            const newValue = parseFloat(e.target.innerText);
                            if (newValue > 100 || newValue < 0 || isNaN(newValue)) {
                              e.target.innerText = `${itemStatus}%`;
                            } else {
                              changeSowProgress(newValue, innerIndex, dataIndex);
                              itemStatus += newValue;
                              e.target.innerText = `${newValue}%`;
                            }
                            e.target.contentEditable = false;
                            e.target.style.paddingLeft = '8px';
                            e.target.style.paddingRight = '8px';
                          }}
                        >
                          <span>{`${itemStatus}%`}</span>
                        </div>
                        <CProgressBar value={itemStatus} className={colorstatus + ' ' + 'select-design'} />
                      </CProgress>
                    </div>
                  </div>
                </div>
              </CCol>

              <CCol lg="4" className="text-center">
                <CRow>
                  <CCol className="text-center" id="Target/Actual Start">
                    <CRow style={{ paddingBottom: '10px' }}>
                      <CCol xs="12" sm="12" md="12" lg="12">
                        <span style={{ fontSize: '10px', fontWeight: 'bold' }}>Target Start</span>
                      </CCol>
                      <CCol xs="12" sm="12" md="12" lg="12">
                        <DatePicker
                          className="reports"
                          placeholderText="mm/dd/yyyy"
                          value={item.start_date}
                          disabled
                          onChange={(value) => {
                            changestartdate(value, innerIndex, dataIndex, 'target');
                          }}
                        />
                      </CCol>

                      <CCol xs="12" sm="12" md="12" lg="12">
                        <span style={{ fontSize: '10px', fontWeight: 'bold' }}>Actual Start</span>
                      </CCol>
                      <CCol xs="12" sm="12" md="12" lg="12">
                        {/* {item.target_date ? item.target_date : 'mm/dd/yyyy'} */}
                        <DatePicker
                          className="reports"
                          placeholderText="mm/dd/yyyy"
                          selected={item.actual_start_date ? new Date(item.actual_start_date) : null}
                          value={item.actual_start_date}
                          isClearable
                          onChange={(value) => {
                            changestartdate(adjustDateFormat(value ? value.toLocaleDateString() : null), innerIndex, dataIndex, 'actual');
                          }}
                          disabled={!item.is_editable}
                        />
                      </CCol>
                    </CRow>
                  </CCol>
                  <CCol className="text-center" id="Target/Actual End">
                    <CRow style={{ paddingBottom: '10px' }}>
                      <CCol xs="12" sm="12" md="12" lg="12">
                        {' '}
                        <span style={{ fontSize: '10px', fontWeight: 'bold' }}>Target End</span>
                      </CCol>
                      <CCol xs="12" sm="12" md="12" lg="12">
                        <DatePicker
                          className="reports"
                          placeholderText="mm/dd/yyyy"
                          value={item.end_date}
                          disabled
                          onChange={(value) => {
                            changestartdate(value, innerIndex, dataIndex, 'target');
                          }}
                        />
                      </CCol>
                      <CCol xs="12" sm="12" md="12" lg="12">
                        {' '}
                        <span style={{ fontSize: '10px', fontWeight: 'bold' }}>Actual End</span>
                      </CCol>
                      <CCol xs="12" sm="12" md="12" lg="12">
                        <DatePicker
                          className="reports"
                          placeholderText="mm/dd/yyyy"
                          selected={item.actual_end_date ? new Date(item.actual_end_date) : null}
                          value={item.actual_end_date}
                          isClearable
                          onChange={(value) => {
                            changeenddate(adjustDateFormat(value ? value.toLocaleDateString() : null), innerIndex, dataIndex, 'actual');
                          }}
                          disabled={!item.is_editable}
                        />
                      </CCol>
                    </CRow>
                  </CCol>
                </CRow>
              </CCol>

              <CCol lg="2" style={{ padding: '10px' }} id="Comment/s">
                <CCol>
                  <span style={{ fontSize: '10px', fontWeight: 'bold' }}>Comment/s</span>
                </CCol>
                <CButton color="aimhi-textlight-neutral" size="sm" block onClick={() => openModalComment(item)}>
                  {' '}
                  View
                </CButton>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      );
    });
    return (
      <CCol key={index} style={{ marginTop: '20px' }}>
        <CCard style={{ marginBottom: 0 }} className="shadow" onClick={() => toggleShow(data.phase_id)}>
          <CCardBody>
            <CRow>
              <CCol lg="4">
                {/* <CCol lg="4" onClick={() => toggleShow(data.phase_id)}> */}
                <span className="circle-number float-left">
                  <span>{index + 1}</span>
                </span>
                <div className="report-sow-label">{data.phase}</div>
              </CCol>

              <CCol lg="2" className="align-center" style={{ marginLeft: '30px' }}>
                <div className="circular-progress-size">
                  <CircularProgressbarWithChildren
                    className="circular-progress-size"
                    value={data.progress.toFixed(2)}
                    text={`${data.progress.toFixed(2)}%`}
                    styles={{
                      path: {
                        stroke: '#351D75',
                        strokeLinecap: 'butt',
                        transition: 'stroke-dashoffset 0.5s ease 0s',
                        transformOrigin: 'center center',
                        circleRatio: '0',
                        strokeWidth: '-10',
                      },
                      trail: {
                        stroke: '#9c9c9c',
                        strokeLinecap: 'butt',
                        transform: 'rotate(0.25turn)',
                        transformOrigin: 'center center',
                        circleRatio: '0',
                        strokeWidth: '-10',
                      },
                      text: {
                        fill: '#23303D',
                        fontSize: '20px',
                        fontWeight: '700',
                      },
                      background: {
                        fill: '#54c2a2',
                      },
                    }}
                  />
                </div>
                <span className="status-label align-text-mobile">Progress</span>
              </CCol>

              <CCol lg="4" xl="2">
                <ItemBadge underBudget={data.under_budget} delayed={data.delayed} incomplete={data.incomplete} />
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
        <CCol xl="12" style={{ padding: 0 }}>
          <CCollapse show={toggle.includes(data.phase_id)}>{collapseCard}</CCollapse>
        </CCol>
      </CCol>
    );
  });

  //   const option = weeks.map((data, Index) => {
  //     return (
  //       <option key={Index} value={`${data.start_date} - ${data.end_date}`}>
  //         {data.start_date} - {data.end_date}
  //       </option>
  //     );
  //   });

  // const option = props.missedDates.map((date, index) => {
  //   return (
  //     <option key={index} value={date}>
  //       {date}
  //     </option>
  //   );
  // });

  const option =
    datesList.length > 0 ? (
      datesList.map((date, index) => {
        console.log('date', date);
        return (
          <option key={index} value={`${date.start_date} - ${date.end_date}`}>
            {`${date.start_date} - ${date.end_date}`}
          </option>
        );
      })
    ) : (
      <option value="default">No dates available</option>
    );

  return (
    <>
      <CCol style={{ marginTop: '20px' }}>
        <CRow>
          <CCol xl="6">
            <CFormGroup row>
              <CLabel>Date Range</CLabel>
              <CCol xl="9">
                <CSelect
                  value={date}
                  onChange={(e) => {
                    getWeeklyData(e.target.value);
                  }}
                >
                  {option}
                </CSelect>
              </CCol>
            </CFormGroup>
          </CCol>
          <CCol xl="2"></CCol>
          <CCol xl="2">
            {/* <CButton color="aimhi-textlight-neutral" variant="outline" size="sm" block onClick={() => {}}>
              Download
            </CButton> */}
          </CCol>

          <CCol xl="2">
            <CButton color="aimhi-textlight-neutral" size="sm" block onClick={saveChanges}>
              Save changes
            </CButton>
          </CCol>
        </CRow>
        {/* <CCol xl="12">
          <span style={{ fontWeight: "bold", fontSize: "16px" }}>{date}</span>
        </CCol> */}
        {missedDates > 0 ? (
          WeeklyCard
        ) : (
          // <CCol xm="12" sm="12" md="12" lg="12" xl="12" style={{ marginTop: '16px' }}>
          //   <CAlert color="aimhi-success" style={{ position: 'relative', cursor: 'pointer', color: '#1b9e3e' }} fade={true}>
          //     <div style={{ position: 'relative' }}>
          //       <Lottie
          //         options={{
          //           loop: true,
          //           autoplay: true,
          //           animationData: animationData,
          //           rendererSettings: {
          //             preserveAspectRatio: 'xMidYMid slice',
          //           },
          //         }}
          //         height={200}
          //         width={1270}
          //       />
          //       <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', color: '#fff', fontSize: '36px', fontWeight: 'bold' }}>You have no missed reports !</div>
          //     </div>
          //   </CAlert>
          // </CCol>

          <CCol xm="12" sm="12" md="12" lg="12" xl="12" style={{ marginTop: '16px', position: 'relative' }}>
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: animationData,
                rendererSettings: {
                  preserveAspectRatio: 'xMidYMid slice',
                },
              }}
              height={500}
              width={1270}
            />
            <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', color: '#3A7D21', fontSize: '36px', fontWeight: 'bold' }}>You have no missed reports !</div>
          </CCol>
        )}
        {isModalCommentOpen ? <ModalComments isModalOpen={isModalCommentOpen} reportData={reportData} onClose={closeModalComment} date={date} type={2} /> : null}
      </CCol>
    </>
  );
};

export default HistoricalReports;
