import React, { useState, useEffect, useRef } from 'react';
import './risk.css';
import { CInput, CLabel, CButton, CSelect, CTooltip } from '@coreui/react';
import { FaRegQuestionCircle } from 'react-icons/fa';
import close from '../../assets/img/close.png';
import axios from 'axios';
import IP from '../../StoreGlobal/IP';
import { eq } from 'lodash';
import { number } from 'prop-types';
import { materialUpload } from 'src/request/Materials';
import { preventInvalidKeys, blurOnWheel, preventInvalidPaste } from 'src/reusable/inputSettings';
const RiskCalculator = ({
  isOpen,
  title,
  sow,
  work_item,
  EstWorkers,
  durationOnchange,
  index,
  scopeIndex,
  toggleCalculator,
  materialName,
  qtyonchange,
  eqdurationonchange,
  laborProductivity,
  equipmentProductivity,
  isSowAdded,
  updateIsSowAdded,
  setCalculatorStatus,
  projectID,
  materialQuantity,
  matquantityonchange,
  areaOrVolumeOnChange,
  areaOrVolumeUnitOnChange,
}) => {
  const Swal = require('sweetalert2');
  let accessToken = localStorage.getItem('token');
  let materialInputOptions = [];
  // const productivityResults = [100, 200, 300, 400, 500, 600, 700, 800, 900, 1000];

  const [areFieldsFilled, setAreFieldsFilled] = useState(true);
  const [buttonColor, setButtonColor] = useState('#f4f4f7');
  const [fontColor, setFontColor] = useState('#757070');
  const [laborInputValues, setLaborInputValues] = useState([]);
  const [equipmentInputValues, setEquipmentInputValues] = useState([]);
  const [materialInputValues, setMaterialInputValues] = useState([]);
  const [laborCalculationCategory, setLaborCalculationCategory] = useState('');
  const [equipmentCalculationCategory, setEquipmentCalculationCategory] = useState('');
  const [areaLaborValid, setAreaLaborValid] = useState(true);
  const [productivityLaborValid, setProductivityLaborValid] = useState(true);
  const [workersLaborValid, setWorkersLaborValid] = useState(true);
  const [durationLaborValid, setDurationLaborValid] = useState(true);

  const [areaEquipmentValid, setAreaEquipmentValid] = useState(true);
  const [productivityEquipmentValid, setProductivityEquipmentValid] = useState(true);
  const [workersEquipmentValid, setWorkersEquipmentValid] = useState(true);
  const [durationEquipmentValid, setDurationEquipmentValid] = useState(true);

  const [areaMaterialValid, setAreaMaterialValid] = useState(true);
  const [numberOfEquipment, setNumberOfEquipment] = useState(null);
  const [equipmentDuration, setEquipmentDuration] = useState(null);
  const [units, setUnits] = useState({});
  const [numberOfWorkers, setNumberOfWorkers] = useState(null);
  const [laborDuration, setLaborDuration] = useState(null);
  const [isFocusedProductivityLabor, setIsFocusedProductivityLabor] = useState(false);
  const [isFocusedProductivityEquipment, setIsFocusedProductivityEquipment] = useState(false);
  const [noOfColumnsMaterialValid, setNoOfColumnsMaterialValid] = useState(true);
  const [noOfPipesMaterialValid, setNoOfPipesMaterialValid] = useState(true);

  const timerRef = useRef(null);

  let description = '';

  if (title == 'Labor') {
    // description = "Labor calculator helps you in estimating the number of workers or the duration. If you want to estimate the number of workers needed, provide the 'Duration' of the project. Alternatively, if you want to estimate the project duration, enter the 'Number of Estimated Workers.'";
    description = "The Labor Calculator estimates workers or project duration. Input 'Duration' for worker count or 'No. of Estimated Workers' for project duration.";
  } else if (title == 'Equipment') {
    // description = "Equipment calculator helps you in estimating the number of equipment or the duration. If you want to estimate the number of equipment needed, provide the 'Duration' of the project. Alternatively, if you want to estimate the project duration, enter the 'Number of Equipment'.";
    description = "The Equipment Calculator estimates equipment or project duration. Input 'Duration' for equipment count or 'Number of Equipment' for project duration.";
  } else if (title == 'Material') {
    // description = 'Material Calculation helps you estimate material quantity. Estimates were based from the book of Max Fajardo. Kindly input the necessary information to the different fields.By clicking the apply button the result from the calculation will be applied directly to your estimates.';
    description = "The Material Calculator assists in estimating material quantity, utilizing data from Max Fajardo's book. Input required information into the provided fields, and upon clicking 'Apply,' the calculated result will be directly applied to your estimates.";
  }

  const areAllFieldsFilled = () => {
    let allFieldsFilled;
    if (title === 'Labor') {
      allFieldsFilled =
        laborInputValues[scopeIndex]?.[index]?.area &&
        laborInputValues[scopeIndex]?.[index]?.productivity &&
        (laborInputValues[scopeIndex]?.[index]?.workers || numberOfWorkers) &&
        (laborInputValues[scopeIndex]?.[index]?.duration || laborDuration) &&
        areaLaborValid &&
        productivityLaborValid &&
        durationLaborValid &&
        workersLaborValid;
    } else if (title === 'Equipment') {
      allFieldsFilled =
        equipmentInputValues[scopeIndex]?.[index]?.area &&
        equipmentInputValues[scopeIndex]?.[index]?.productivity &&
        (equipmentInputValues[scopeIndex]?.[index]?.equipments || numberOfEquipment) &&
        (equipmentInputValues[scopeIndex]?.[index]?.duration || equipmentDuration) &&
        areaEquipmentValid &&
        productivityEquipmentValid &&
        durationEquipmentValid &&
        workersEquipmentValid;
    } else if (title === 'Material') {
      // Check if all input fields for the material have values
      const area = materialInputValues[scopeIndex]?.[index]?.area;
      const size = materialInputValues[scopeIndex]?.[index]?.size;
      // const mixture_type = materialInputValues[scopeIndex]?.[index]?.mixture_type;
      const cement_type = materialInputValues[scopeIndex]?.[index]?.type;
      const cement_weight = materialInputValues[scopeIndex]?.[index]?.weight;
      const thickness = materialInputValues[scopeIndex]?.[index]?.thickness;
      const height = materialInputValues[scopeIndex]?.[index]?.height;
      const number_of_column = materialInputValues[scopeIndex]?.[index]?.number_of_columns;
      const category_size = materialInputValues[scopeIndex]?.[index]?.category_size;
      const no_of_pipes = materialInputValues[scopeIndex]?.[index]?.no_of_pipes;
      const diameter_of_pipe = materialInputValues[scopeIndex]?.[index]?.diameter_of_pipe;
      const diameter_of_hole = materialInputValues[scopeIndex]?.[index]?.diameter_of_hole;
      const board_size = materialInputValues[scopeIndex]?.[index]?.board_size;

      let optionalFields =
        [area, size, cement_type, cement_weight, thickness, height, number_of_column, category_size, no_of_pipes, diameter_of_pipe, diameter_of_hole, board_size].includes(undefined) ||
        size ||
        cement_type ||
        cement_type ||
        thickness ||
        height ||
        number_of_column ||
        no_of_pipes ||
        category_size ||
        diameter_of_hole ||
        diameter_of_pipe ||
        board_size;
      allFieldsFilled = optionalFields && materialInputValues[scopeIndex]?.[index]?.materialQuantity && areaMaterialValid;
    }
    setAreFieldsFilled(!allFieldsFilled);
  };

  useEffect(() => {
    // Check if all fields are filled
    areAllFieldsFilled();
    // Set button and font color based on the condition
    if (areAllFieldsFilled) {
      setButtonColor('#23303D');
      setFontColor('#ffffff');
    } else {
      setButtonColor('#f4f4f7');
      setFontColor('#757070');
    }
  }, [laborInputValues, equipmentInputValues, materialInputValues, scopeIndex, index]);

  useEffect(() => {
    if (title === 'Labor') {
      handleLaborInputChange(scopeIndex, index, 'phase_id', laborProductivity[0]?.phase_id);
      handleLaborInputChange(scopeIndex, index, 'sow_id', laborProductivity[0]?.sow_id);
      handleLaborInputChange(scopeIndex, index, 'type_id', laborProductivity[0]?.type_id);
      handleLaborInputChange(scopeIndex, index, 'area', laborProductivity[0]?.area_value || laborProductivity[0]?.area);
      handleLaborInputChange(scopeIndex, index, 'area_unit_id', laborProductivity[0]?.area_unit_id || 7);
      handleLaborInputChange(scopeIndex, index, 'duration_unit_id', 36);
      handleLaborInputChange(scopeIndex, index, 'productivity_history', laborProductivity[0]?.productivity_history || []);
      handleEquipmentInputChange(scopeIndex, index, 'productivity', equipmentProductivity[0]?.productivity || '');
      if (laborProductivity && laborProductivity.length > 0 && laborProductivity[0].productivity !== null) {
        handleLaborInputChange(scopeIndex, index, 'productivity', laborProductivity[0].productivity);
        handleLaborInputChange(scopeIndex, index, 'productivity_unit', JSON.parse(`"${laborProductivity[0].productivity_unit}"`));
        handleLaborInputChange(scopeIndex, index, 'area_unit', JSON.parse(`"${laborProductivity[0].area_unit}"`));
        handleLaborInputChange(scopeIndex, index, 'duration_unit', JSON.parse(`"${laborProductivity[0].time_unit}"`));
        handleLaborInputChange(scopeIndex, index, 'duration_unit_id', laborProductivity[0].time_unit_id);
        handleLaborInputChange(scopeIndex, index, 'phase_id', laborProductivity[0].phase_id);
        handleLaborInputChange(scopeIndex, index, 'sow_id', laborProductivity[0].sow_id);
        handleLaborInputChange(scopeIndex, index, 'type_id', laborProductivity[0].type_id);
        handleLaborInputChange(scopeIndex, index, 'area_unit_id', laborProductivity[0].area_unit_id);
        handleLaborInputChange(scopeIndex, index, 'duration', laborProductivity[0]?.duration);
        handleLaborInputChange(scopeIndex, index, 'workers', laborProductivity[0]?.workers);
        handleLaborInputChange(scopeIndex, index, 'area', laborProductivity[0]?.area_value || laborProductivity[0]?.area);
      }
    }
  }, [laborProductivity]);

  useEffect(() => {
    if (title === 'Equipment') {
      console.log('equipmentProductivity', equipmentProductivity);
      handleEquipmentInputChange(scopeIndex, index, 'phase_id', equipmentProductivity[0]?.phase_id);
      handleEquipmentInputChange(scopeIndex, index, 'sow_id', equipmentProductivity[0]?.sow_id);
      handleEquipmentInputChange(scopeIndex, index, 'type_id', equipmentProductivity[0]?.type_id);
      handleEquipmentInputChange(scopeIndex, index, 'area', equipmentProductivity[0]?.area_value || equipmentProductivity[0]?.area);
      handleEquipmentInputChange(scopeIndex, index, 'area_unit_id', equipmentProductivity[0]?.area_unit_id || 7);
      handleEquipmentInputChange(scopeIndex, index, 'duration_unit_id', 36);
      handleEquipmentInputChange(scopeIndex, index, 'productivity_history', equipmentProductivity[0]?.productivity_history || []);
      handleEquipmentInputChange(scopeIndex, index, 'productivity', equipmentProductivity[0]?.productivity || '');
      if (equipmentProductivity && equipmentProductivity.length > 0 && equipmentProductivity[0].productivity !== null) {
        handleEquipmentInputChange(scopeIndex, index, 'productivity', equipmentProductivity[0].productivity);
        handleEquipmentInputChange(scopeIndex, index, 'productivity_unit', JSON.parse(`"${equipmentProductivity[0].productivity_unit}"`));
        handleEquipmentInputChange(scopeIndex, index, 'area_unit', JSON.parse(`"${equipmentProductivity[0].area_unit}"`));
        handleEquipmentInputChange(scopeIndex, index, 'duration_unit', JSON.parse(`"${equipmentProductivity[0].time_unit}"`));
        handleEquipmentInputChange(scopeIndex, index, 'duration_unit_id', equipmentProductivity[0].time_unit_id);
        handleEquipmentInputChange(scopeIndex, index, 'phase_id', equipmentProductivity[0].phase_id);
        handleEquipmentInputChange(scopeIndex, index, 'sow_id', equipmentProductivity[0].sow_id);
        handleEquipmentInputChange(scopeIndex, index, 'type_id', equipmentProductivity[0].type_id);
        handleEquipmentInputChange(scopeIndex, index, 'area_unit_id', equipmentProductivity[0].area_unit_id);
        handleEquipmentInputChange(scopeIndex, index, 'duration', equipmentProductivity[0].duration);
        handleEquipmentInputChange(scopeIndex, index, 'equipments', equipmentProductivity[0].equipments);
        handleEquipmentInputChange(scopeIndex, index, 'area', equipmentProductivity[0]?.area_value || equipmentProductivity[0]?.area);
      }
      // TODO: find a way to set area_unit_id if from history backup
    }
  }, [equipmentProductivity]);

  useEffect(() => {
    if (title === 'Material') {
      if (materialQuantity.length > 0) {
        handleMaterialInputChange(scopeIndex, index, 'sow_id', materialQuantity[0].sow_id);
        handleMaterialInputChange(scopeIndex, index, 'input', materialQuantity[0].input[0]);
        handleMaterialInputChange(scopeIndex, index, 'material_name', materialName.label);
        handleMaterialInputChange(scopeIndex, index, 'materialQuantity', '');
        handleMaterialInputChange(scopeIndex, index, 'area', materialQuantity[0]?.area_value || materialQuantity[0].data[0]?.unit_area_value);
      }
      if (materialQuantity && materialQuantity.length > 0 && (materialQuantity[0].data[0].unit_area_value || materialQuantity[0].data[0].no_of_pipes || materialQuantity[0].data[0].number_of_columns)) {
        handleMaterialInputChange(scopeIndex, index, 'area', materialQuantity[0].data[0].unit_area_value);
        handleMaterialInputChange(scopeIndex, index, 'size', materialQuantity[0].data[0].tile_pieces_id || materialQuantity[0]?.data[0]?.size_option_id);
        handleMaterialInputChange(scopeIndex, index, 'areaUnitID', materialQuantity[0].data[0].unit_area_id);
        handleMaterialInputChange(scopeIndex, index, 'sow_id', materialQuantity[0].data[0].sow_id);
        handleMaterialInputChange(scopeIndex, index, 'type', materialQuantity[0].data[0].cement_type);
        handleMaterialInputChange(scopeIndex, index, 'sow_id', materialQuantity[0].sow_id);
        handleMaterialInputChange(scopeIndex, index, 'weight', materialQuantity[0].data[0].cement_weight);
        handleMaterialInputChange(scopeIndex, index, 'thickness', materialQuantity[0].data[0].thickness);
        handleMaterialInputChange(scopeIndex, index, 'height', materialQuantity[0].data[0].height);
        handleMaterialInputChange(scopeIndex, index, 'no_of_columns', materialQuantity[0].data[0].number_of_columns);
        handleMaterialInputChange(scopeIndex, index, 'category', materialQuantity[0].data[0].category_size);
        handleMaterialInputChange(scopeIndex, index, 'no_of_pipes', materialQuantity[0].data[0].no_of_pipes);
        handleMaterialInputChange(scopeIndex, index, 'diameter_of_pipe', materialQuantity[0].data[0].diameter_of_pipe);
        handleMaterialInputChange(scopeIndex, index, 'diameter_of_hole', materialQuantity[0].data[0].diameter_of_hole);
        handleMaterialInputChange(scopeIndex, index, 'board_size', materialQuantity[0].data[0].board_size);
      }
      if (materialQuantity[0]?.data[0]?.unit_area_value || materialQuantity[0]?.data[0]?.no_of_pipes || materialQuantity[0]?.data[0]?.number_of_columns) {
        calculateMaterialQuantity({
          size_id: materialQuantity[0]?.data[0]?.tile_pieces_id || materialQuantity[0]?.data[0]?.size_option_id,
          sow_id: materialQuantity[0]?.sow_id,
          area_value: materialQuantity[0]?.data[0]?.unit_area_value,
          cement_type: materialQuantity[0]?.data[0]?.cement_type,
          cement_weight: materialQuantity[0]?.data[0]?.cement_weight,
          thickness: materialQuantity[0]?.data[0]?.thickness,
          no_of_pipes: materialQuantity[0]?.data[0]?.no_of_pipes,
          diameter_of_pipe: materialQuantity[0]?.data[0]?.diameter_of_pipe,
          diameter_of_hole: materialQuantity[0]?.data[0]?.diameter_of_hole,
          number_of_columns: materialQuantity[0]?.data[0]?.number_of_columns,
          category_size: materialQuantity[0]?.data[0]?.category_size,
          height: materialQuantity[0]?.data[0]?.height,
          board_size: materialQuantity[0]?.data[0]?.board_size,
        });
      }
    }
  }, [materialQuantity]);

  const prevIsSowAdded = useRef(isSowAdded);

  useEffect(() => {
    if (isSowAdded && !prevIsSowAdded.current) {
      const updatedLaborInputValues = [...laborInputValues];
      const updatedEquipmentInputValues = [...equipmentInputValues];
      const updatedMaterialInputValues = [...materialInputValues];

      // Check if there are elements in the array
      if (updatedLaborInputValues.length > 0) {
        // Remove the last element
        const lastElementLabor = updatedLaborInputValues.pop();

        // Add an empty object at the beginning
        updatedLaborInputValues.unshift({});

        // Add the last element to the new position
        updatedLaborInputValues.push(lastElementLabor);

        setLaborInputValues(updatedLaborInputValues);
      } else {
        console.log('Not enough elements in the array to shift');
      }

      if (updatedEquipmentInputValues.length > 0) {
        // Remove the last element
        const lastElementEquipment = updatedEquipmentInputValues.pop();

        // Add an empty object at the beginning
        updatedEquipmentInputValues.unshift({});

        // Add the last element to the new position
        updatedEquipmentInputValues.push(lastElementEquipment);

        setEquipmentInputValues(updatedEquipmentInputValues);
      } else {
        console.log('Not enough equipment elements in the array to shift');
      }

      if (updatedMaterialInputValues.length > 0) {
        // Remove the last element
        const lastElementMaterial = updatedMaterialInputValues.pop();

        // Add an empty object at the beginning
        updatedMaterialInputValues.unshift({});

        // Add the last element to the new position
        updatedMaterialInputValues.push(lastElementMaterial);

        setMaterialInputValues(updatedMaterialInputValues);
      } else {
        console.log('Not enough material elements in the array to shift');
      }

      // Set isSowAdded to false after the shift operation
      updateIsSowAdded(() => false);
    }

    prevIsSowAdded.current = isSowAdded;
  }, [isSowAdded, laborInputValues, laborProductivity]);

  useEffect(() => {
    getUnits();
  }, []);

  useEffect(() => {
    if (materialInputValues[scopeIndex]?.[index]?.area > 0) {
      // Set a new timer
      timerRef.current = setTimeout(() => {
        calculateMaterialQuantity();
      }, 300);
    }
  }, [materialInputValues[scopeIndex]?.[index]?.area]);

  const getUnits = () => {
    axios
      .get(IP + 'estimate/units')
      .then((res) => {
        if (res.status == 200) {
          setUnits(res.data.response);
        } else {
          Swal.fire({
            icon: 'error',
            title: res.data.response,
            text: res.data.description,
            confirmButtonColor: '#23303D',
          });
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  const calculateLaborDuration = async (props) => {
    try {
      if (areaLaborValid && productivityLaborValid) {
        const response = await axios.post(
          IP + 'calculator/',
          {
            type: 1, // Assuming type 1 corresponds to labor calculations
            sow_id: laborInputValues[scopeIndex]?.[index]?.sow_id,
            phase_id: laborInputValues[scopeIndex]?.[index]?.phase_id,
            area: props.area ? parseInt(props.area) : parseFloat(laborInputValues[scopeIndex]?.[index]?.area), // Make sure to convert to a number if needed
            worker: parseInt(props.worker), // Convert to integer if needed
            duration: null,
            unit_duration: laborInputValues[scopeIndex]?.[index]?.duration_unit_id || 36,
            productivity: props.productivity ? parseInt(props.productivity) : parseFloat(laborInputValues[scopeIndex]?.[index]?.productivity),
            productivity_unit_time: laborInputValues[scopeIndex]?.[index]?.duration_unit_id || 36,
            project_id: null,
            type_id: null,
            unit_area_id: laborInputValues[scopeIndex]?.[index]?.area_unit_id,
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-Type': 'application/json',
            },
          },
        );

        if (response.data) {
          let estimatedDuration = Math.ceil(response.data.response.duration);
          // setDuration(estimatedDuration);
          if (estimatedDuration > 0) {
            if (durationLaborValid === false) {
              setDurationLaborValid(!durationLaborValid);
            }
            handleLaborInputChange(scopeIndex, index, 'duration', estimatedDuration);
          }
        }
      }
    } catch (error) {
      // Handle errors
      console.error('Error making request:', error);
    }
  };

  const calculateWorker = async (props) => {
    try {
      if (areaLaborValid && productivityLaborValid) {
        const response = await axios.post(
          IP + 'calculator/',
          {
            type: 1,
            sow_id: laborInputValues[scopeIndex]?.[index]?.sow_id,
            phase_id: laborInputValues[scopeIndex]?.[index]?.phase_id,
            area: props.area ? parseInt(props.area) : parseFloat(laborInputValues[scopeIndex]?.[index]?.area),
            worker: null,
            duration: parseFloat(props.duration),
            unit_duration: laborInputValues[scopeIndex]?.[index]?.duration_unit_id || 36,
            productivity: props.productivity ? parseInt(props.productivity) : parseInt(laborInputValues[scopeIndex]?.[index]?.productivity),
            productivity_unit_time: laborInputValues[scopeIndex]?.[index]?.duration_unit_id,
            project_id: null,
            type_id: null,
            unit_area_id: laborInputValues[scopeIndex]?.[index]?.area_unit_id,
            productivity_unit_time: laborInputValues[scopeIndex]?.[index]?.duration_unit_id || 36,
          },
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-Type': 'application/json',
            },
          },
        );

        if (response.data) {
          let numberOfWorkers = Math.ceil(response.data.response.worker);

          if (numberOfWorkers > 0) {
            if (workersLaborValid === false) {
              setWorkersLaborValid(!workersLaborValid);
            }
            handleLaborInputChange(scopeIndex, index, 'workers', numberOfWorkers);
          }
        }
      }
    } catch (error) {
      // Handle errors
      console.error('Error making request:', error);
    }
  };

  const saveLaborCalculation = async (props) => {
    try {
      const response = await axios.post(
        IP + 'calculator/create',
        {
          type: 1,
          sow_id: laborInputValues[scopeIndex]?.[index]?.sow_id,
          phase_id: laborInputValues[scopeIndex]?.[index]?.phase_id,
          unit_area_id: laborInputValues[scopeIndex]?.[index]?.area_unit_id,
          unit_area_value: parseFloat(laborInputValues[scopeIndex]?.[index]?.area),
          duration: laborInputValues[scopeIndex]?.[index]?.duration,
          unit_duration: laborInputValues[scopeIndex]?.[index]?.duration_unit_id,
          productivity: parseFloat(laborInputValues[scopeIndex]?.[index]?.productivity),
          productivity_unit_time_id: laborInputValues[scopeIndex]?.[index]?.duration_unit_id || 36,
          type_count: laborInputValues[scopeIndex]?.[index]?.workers,
          type_id: laborInputValues[scopeIndex]?.[index]?.type_id,
          project_id: projectID,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        },
      );

      if (response.data.status === 200) {
        setCalculatorStatus('Labor', scopeIndex, index);
        if (laborInputValues[scopeIndex]?.[index]?.duration_unit_id === 36) {
          let durationDay = Math.ceil(laborInputValues[scopeIndex]?.[index]?.duration / 8);

          EstWorkers(laborInputValues[scopeIndex]?.[index]?.workers, scopeIndex, index, 1);
          durationOnchange(durationDay.toString(), scopeIndex, index, 1);
        } else {
          EstWorkers(laborInputValues[scopeIndex]?.[index]?.workers, scopeIndex, index, 1);
          durationOnchange(laborInputValues[scopeIndex]?.[index]?.duration.toString(), scopeIndex, index, 1);
        }
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Save Failed',
          text: 'There was a problem saving the labor calculation.',
          confirmButtonColor: '#23303D',
        });
      }
    } catch (error) {
      // Handle errors
      console.error('Error making request:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Error saving labor calculation',
        confirmButtonColor: '#23303D',
      });
    }
  };

  const calculateEquipmentDuration = async (props) => {
    try {
      const response = await axios.post(
        IP + 'calculator/',
        {
          type: 2, // Assuming type 1 corresponds to labor calculations
          sow_id: equipmentInputValues[scopeIndex]?.[index]?.sow_id,
          phase_id: equipmentInputValues[scopeIndex]?.[index]?.phase_id,
          area: props.area ? parseInt(props.area) : parseFloat(equipmentInputValues[scopeIndex]?.[index]?.area), // Make sure to convert to a number if needed
          equipment: parseInt(props.worker) || parseInt(equipmentInputValues[scopeIndex]?.[index].equipments), // Convert to integer if needed
          duration: null,
          unit_duration: equipmentInputValues[scopeIndex]?.[index]?.duration_unit_id || 36,
          productivity: props.productivity ? parseInt(props.productivity) : parseFloat(equipmentInputValues[scopeIndex]?.[index]?.productivity),
          productivity_unit_time: equipmentInputValues[scopeIndex]?.[index]?.duration_unit_id,
          project_id: null,
          type_id: null,
          unit_area_id: equipmentInputValues[scopeIndex]?.[index]?.area_unit_id,
          productivity_unit_time: equipmentInputValues[scopeIndex]?.[index]?.duration_unit_id || 36,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        },
      );

      if (response.data) {
        let estimatedDuration = Math.ceil(response.data.response.duration);

        if (estimatedDuration > 0) {
          if (durationEquipmentValid === false) {
            setDurationEquipmentValid(!durationEquipmentValid);
          }
          handleEquipmentInputChange(scopeIndex, index, 'duration', estimatedDuration);
        }
      }
    } catch (error) {
      // Handle errors
      console.error('Error making request:', error);
    }
  };

  const calculateEquipment = async (props) => {
    try {
      const response = await axios.post(
        IP + 'calculator/',
        {
          type: 2, // Assuming type 1 corresponds to labor calculations
          sow_id: equipmentInputValues[scopeIndex]?.[index]?.sow_id,
          phase_id: equipmentInputValues[scopeIndex]?.[index]?.phase_id,
          area: props.area ? parseInt(props.area) : parseFloat(equipmentInputValues[scopeIndex]?.[index]?.area), // Make sure to convert to a number if needed
          equipment: null, // Convert to integer if needed
          duration: parseFloat(props.duration),
          unit_duration: equipmentInputValues[scopeIndex]?.[index]?.duration_unit_id || 36,
          productivity: props.productivity ? parseInt(props.productivity) : parseInt(equipmentInputValues[scopeIndex]?.[index]?.productivity),
          productivity_unit_time: equipmentInputValues[scopeIndex]?.[index]?.duration_unit_id,
          project_id: null,
          type_id: null,
          unit_area_id: equipmentInputValues[scopeIndex]?.[index]?.area_unit_id,
          productivity_unit_time: equipmentInputValues[scopeIndex]?.[index]?.duration_unit_id || 36,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        },
      );

      if (response.data) {
        let numberOfEquipments = Math.ceil(response.data.response.equipment);

        if (numberOfEquipments > 0) {
          if (workersEquipmentValid === false) {
            setWorkersEquipmentValid(!workersEquipmentValid);
          }
          handleEquipmentInputChange(scopeIndex, index, 'equipments', numberOfEquipments);
        }
      }
    } catch (error) {
      // Handle errors
      console.error('Error making request:', error);
    }
  };

  const saveEquipmentCalculation = async (props) => {
    try {
      const response = await axios.post(
        IP + 'calculator/create',
        {
          type: 2,
          sow_id: equipmentInputValues[scopeIndex]?.[index]?.sow_id,
          phase_id: equipmentInputValues[scopeIndex]?.[index]?.phase_id,
          unit_area_id: equipmentInputValues[scopeIndex]?.[index]?.area_unit_id,
          unit_area_value: parseFloat(equipmentInputValues[scopeIndex]?.[index]?.area),
          duration: equipmentInputValues[scopeIndex]?.[index]?.duration,
          unit_duration: equipmentInputValues[scopeIndex]?.[index]?.duration_unit_id,
          productivity: parseInt(equipmentInputValues[scopeIndex]?.[index]?.productivity),
          productivity_unit_time_id: equipmentInputValues[scopeIndex]?.[index]?.duration_unit_id || 36,
          type_count: equipmentInputValues[scopeIndex]?.[index]?.equipments || numberOfEquipment,
          type_id: equipmentInputValues[scopeIndex]?.[index]?.type_id,
          project_id: projectID,
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        },
      );

      if (response.data.status === 200) {
        setCalculatorStatus('Equipment', scopeIndex, index);
        if (equipmentInputValues[scopeIndex]?.[index]?.duration_unit_id === 36) {
          let durationDay = Math.ceil(equipmentInputValues[scopeIndex]?.[index]?.duration / 8);
          qtyonchange(equipmentInputValues[scopeIndex]?.[index]?.equipments || numberOfEquipment, scopeIndex, index);
          eqdurationonchange(durationDay.toString(), scopeIndex, index);
        } else {
          qtyonchange(equipmentInputValues[scopeIndex]?.[index]?.equipments || numberOfEquipment, scopeIndex, index);
          eqdurationonchange(equipmentInputValues[scopeIndex]?.[index]?.duration.toString(), scopeIndex, index);
        }
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Save Failed',
          text: 'There was a problem saving the equipment calculation.',
          confirmButtonColor: '#23303D',
        });
      }
    } catch (error) {
      // Handle errors
      console.error('Error making request:', error);
    }
  };

  const calculateMaterialQuantity = async (props) => {
    try {
      const response = await axios.post(
        IP + 'calculator/',
        {
          type: 3,
          type_id: parseFloat(materialQuantity[0].type_id),
          area_unit: materialInputValues[scopeIndex]?.[index]?.areaUnitID ? parseInt(materialInputValues[scopeIndex]?.[index]?.areaUnitID) : null,
          area_value: parseFloat(materialInputValues[scopeIndex]?.[index]?.area) || parseFloat(props?.area_value) || null,
          size_option_id: props?.size_id ? props.size_id : parseInt(materialInputValues[scopeIndex]?.[index]?.size),
          sow_id: materialInputValues[scopeIndex]?.[index]?.sow_id || props.sow_id,
          phase_id: parseInt(materialQuantity[0].phase_id),
          // mixture_type: materialInputValues[scopeIndex]?.[index]?.mixture_type ? materialInputValues[scopeIndex]?.[index]?.mixture_type || props.mixture_type : null,
          // mixture_type: props?.mixture_type || materialInputValues[scopeIndex]?.[index]?.mixture_type || null,
          cement_type: props?.cement_type || materialInputValues[scopeIndex]?.[index]?.type || null,
          cement_weight: parseInt(props?.cement_weight, 10) || parseInt(materialInputValues[scopeIndex]?.[index]?.weight, 10) || null,
          thickness: parseFloat(props?.thickness, 10) || parseFloat(materialInputValues[scopeIndex]?.[index]?.thickness, 10) || null,
          height: parseInt(props?.height) || parseInt(materialInputValues[scopeIndex]?.[index]?.height) || null,
          number_of_columns: parseInt(props?.number_of_columns) || parseInt(materialInputValues[scopeIndex]?.[index]?.no_of_columns) || null,
          category_size: props?.category_size || materialInputValues[scopeIndex]?.[index]?.category || null,
          no_of_pipes: parseInt(props?.no_of_pipes) || parseInt(materialInputValues[scopeIndex]?.[index]?.no_of_pipes) || null,
          diameter_of_pipe: parseInt(props?.diameter_of_pipe) || parseInt(materialInputValues[scopeIndex]?.[index]?.diameter_of_pipe) || null,
          diameter_of_hole: parseInt(props?.diameter_of_hole) || parseInt(materialInputValues[scopeIndex]?.[index]?.diameter_of_hole) || null,
          board_size: props?.board_size || materialInputValues[scopeIndex]?.[index]?.board_size?.replace(' cm', '') || null,

          //   {
          //     "type": 3,
          //     "type_id": 10744,
          //     "phase_id": 12  ,
          //     "sow_id": 2351,
          //     "area_unit": 7,
          //     "area_value": 100,
          //     "size_option_id": 12,
          //     "cement_type":null,
          //     "cement_weight": null,
          //     "thickness": null,
          //     "height": null,
          //     "number_of_columns": null,
          //     "category_size":null,
          //     "no_of_pipes": null,
          //     "diameter_of_pipe": null,
          //     "diameter_of_hole": null
          // }
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        },
      );

      if (response.data) {
        if (materialInputValues[scopeIndex]?.[index]?.area === 0 || areaMaterialValid === false) {
          handleMaterialInputChange(scopeIndex, index, 'materialQuantity', '');
        }
        if (response.data.number_of_pieces > 0) {
          handleMaterialInputChange(scopeIndex, index, 'materialQuantity', response.data.number_of_pieces);
        } else if (response.data.number_of_material > 0) {
          handleMaterialInputChange(scopeIndex, index, 'materialQuantity', response.data.number_of_material);
        }
      }
    } catch (error) {
      // Handle errors
      console.error('Error making request:', error);
    }
  };

  const saveMaterialCalculation = async (props) => {
    try {
      const response = await axios.post(
        IP + 'calculator/create',
        {
          type: 3,
          type_id: parseFloat(materialQuantity[0].type_id),
          sow_id: parseInt(materialQuantity[0].sow_id),
          phase_id: parseInt(materialQuantity[0].phase_id),
          unit_area_id: materialInputValues[scopeIndex]?.[index]?.areaUnitID ? parseInt(materialInputValues[scopeIndex]?.[index]?.areaUnitID) : 7,
          unit_area_value: parseFloat(materialInputValues[scopeIndex]?.[index]?.area) || null,
          size_option_id: parseInt(materialInputValues[scopeIndex]?.[index]?.size) || null,
          project_id: projectID,
          // mixture_type: materialInputValues[scopeIndex]?.[index]?.mixture_type ? materialInputValues[scopeIndex]?.[index]?.mixture_type.toString() : null,
          cement_type: props?.type || materialInputValues[scopeIndex]?.[index]?.type || null,
          cement_weight: parseInt(props?.weight, 10) || parseInt(materialInputValues[scopeIndex]?.[index]?.weight, 10) || null,
          thickness: parseInt(props?.thickness, 10) || parseInt(materialInputValues[scopeIndex]?.[index]?.thickness, 10) || null,
          height: props?.height || materialInputValues[scopeIndex]?.[index]?.height || null,
          number_of_columns: props?.number_of_columns || parseInt(materialInputValues[scopeIndex]?.[index]?.no_of_columns) || null,
          category_size: props?.category_size || materialInputValues[scopeIndex]?.[index]?.category || null,
          no_of_pipes: parseInt(props?.no_of_pipes) || parseInt(materialInputValues[scopeIndex]?.[index]?.no_of_pipes) || null,
          diameter_of_pipe: parseInt(props?.diameter_of_pipe) || parseInt(materialInputValues[scopeIndex]?.[index]?.diameter_of_pipe) || null,
          diameter_of_hole: parseInt(props?.diameter_of_hole) || parseInt(materialInputValues[scopeIndex]?.[index]?.diameter_of_hole) || null,
          board_size: materialInputValues[scopeIndex]?.[index]?.board_size?.replace(' cm', '') || null,
          //   {
          //     "type": 3,
          //     "type_id": 10744,
          //     "phase_id": 12  ,
          //     "sow_id": 2351,
          //     "project_id": 187,
          //     "area_unit": 7,
          //     "area_value": 800,
          //     "size_option_id": 12,
          //     "cement_type": null,
          //     "cement_weight": null,
          //     "thickness": null,
          //     "height": null,
          //     "number_of_columns": null,
          //     "category_size": null,
          //     "height_unit": null,
          //     "no_of_pipes": null,
          //     "diameter_of_pipe": null,
          //     "diameter_of_hole": null
          // }
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        },
      );

      if (response.data) {
        // Handle the API response here
        console.log('createMaterial', response.data);
      }
    } catch (error) {
      // Handle errors
      console.error('Error making request:', error);
    }
  };

  const handleApply = () => {
    if (title === 'Labor') {
      saveLaborCalculation();
    } else if (title == 'Equipment') {
      saveEquipmentCalculation();
    } else if (title == 'Material') {
      saveMaterialCalculation();
      if(materialQuantity[0].area_value == 0){
        console.log('only trigger if 0')
        areaOrVolumeOnChange(materialInputValues[scopeIndex]?.[index]?.area, scopeIndex);
      }
      setCalculatorStatus('Material', scopeIndex, index);
      matquantityonchange(Math.ceil(materialInputValues[scopeIndex]?.[index]?.materialQuantity), scopeIndex, index, false);
    }
    toggleCalculator({ isApply: true });
    // setOpenWidget(false);
  };

  const validateInput = (enteredValue) => {
    // Check if the entered value is a positive number
    // const isValid = enteredValue === '' || /^\d*\.?\d+$/.test(enteredValue);
    const isValid = enteredValue === '' || (/^\d*\.?\d*$/.test(enteredValue) && parseFloat(enteredValue) >= 0);
    // const hasScientificNotation = /[eE]/.test(enteredValue);

    // return isValid && !hasScientificNotation;
    return isValid;
  };

  const handleInputChange = (e, inputType) => {
    const enteredValue = e.target ? e.target.value : e;
    const isValid = validateInput(enteredValue);

    switch (inputType) {
      case 'area-labor':
        setAreaLaborValid(isValid);
        handleLaborInputChange(scopeIndex, index, 'area', enteredValue);
        areaOrVolumeOnChange(enteredValue, scopeIndex);
        break;
      case 'productivity-labor':
        setProductivityLaborValid(isValid);
        handleLaborInputChange(scopeIndex, index, 'productivity', enteredValue);
        break;
      case 'workers-labor':
        setWorkersLaborValid(isValid);
        handleLaborInputChange(scopeIndex, index, 'workers', enteredValue);
        setNumberOfWorkers(enteredValue);
        break;
      case 'duration-labor':
        setDurationLaborValid(isValid);
        handleLaborInputChange(scopeIndex, index, 'duration', enteredValue);
        break;
      case 'area-equipment':
        setAreaEquipmentValid(isValid);
        handleEquipmentInputChange(scopeIndex, index, 'area', enteredValue);
        areaOrVolumeOnChange(enteredValue, scopeIndex);
        break;
      case 'productivity-equipment':
        setProductivityEquipmentValid(isValid);
        handleEquipmentInputChange(scopeIndex, index, 'productivity', enteredValue);
        break;
      case 'equipments-equipment':
        setWorkersEquipmentValid(isValid);
        handleEquipmentInputChange(scopeIndex, index, 'equipments', enteredValue);
        setNumberOfEquipment(enteredValue);
        break;
      case 'duration-equipment':
        setDurationEquipmentValid(isValid);
        handleEquipmentInputChange(scopeIndex, index, 'duration', enteredValue);
        setEquipmentDuration(enteredValue);
        break;
      case 'area-material':
        setAreaMaterialValid(isValid);
        handleMaterialInputChange(scopeIndex, index, 'area', enteredValue);
        areaOrVolumeOnChange(enteredValue, scopeIndex);
        break;
      case 'material-size':
        handleMaterialInputChange(scopeIndex, index, 'size', enteredValue);
        break;
      case 'area-unit':
        handleMaterialInputChange(scopeIndex, index, 'areaUnitID', enteredValue);
        break;
      case 'material-quantity':
        handleMaterialInputChange(scopeIndex, index, 'materialQuantity', enteredValue);
        break;
      case 'type':
        handleMaterialInputChange(scopeIndex, index, 'type', enteredValue);
        handleMaterialTypeChange(e);
        break;
      case 'weight':
        handleMaterialInputChange(scopeIndex, index, 'weight', enteredValue);
        handleMaterialTypeChange(e);
        break;
      case 'thickness':
        handleMaterialInputChange(scopeIndex, index, 'thickness', enteredValue);
        handleMaterialTypeChange(e);
        break;
      case 'height':
        handleMaterialInputChange(scopeIndex, index, 'height', enteredValue);
        handleMaterialTypeChange(e);
        break;
      case 'no_of_columns':
        setNoOfColumnsMaterialValid(isValid);
        handleMaterialInputChange(scopeIndex, index, 'no_of_columns', enteredValue);
        handleMaterialTypeChange(e);
        break;
      case 'category':
        handleMaterialInputChange(scopeIndex, index, 'category', enteredValue);
        handleMaterialTypeChange(e);
        break;
      case 'no_of_pipes':
        setNoOfPipesMaterialValid(isValid);
        handleMaterialInputChange(scopeIndex, index, 'no_of_pipes', enteredValue);
        handleMaterialTypeChange(e);
        break;
      case 'diameter_of_pipe':
        handleMaterialInputChange(scopeIndex, index, 'diameter_of_pipe', enteredValue);
        handleMaterialTypeChange(e);
        break;
      case 'diameter_of_hole':
        handleMaterialInputChange(scopeIndex, index, 'diameter_of_hole', enteredValue);
        handleMaterialTypeChange(e);
        break;
      case 'board size':
        handleMaterialInputChange(scopeIndex, index, 'board_size', enteredValue);
        handleMaterialTypeChange(e);
        break;
      default:
        console.log('Unhandled input type');
    }

    // Update the state variable for validation

    // If the entered value is valid, update the state or perform additional logic
    if (isValid) {
      // Additional logic based on inputType
      console.log('Input is valid');
      switch (inputType) {
        case 'area-labor':
          handleLaborAreaChange(e);
          areaOrVolumeOnChange(enteredValue, scopeIndex);
          break;
        case 'productivity-labor':
          handleLaborProductivityChange(e);
          break;
        case 'workers-labor':
          handleEstimatedWorkersChange(e);
          break;
        case 'duration-labor':
          handleLaborDurationChange(e);
          break;
        case 'area-equipment':
          handleEquipmentAreaChange(e);
          // areaOrVolumeOnChange(enteredValue, scopeIndex);
          break;
        case 'productivity-equipment':
          handleEquipmentProductivityChange(e);
          break;
        case 'equipments-equipment':
          handleEstimatedEquipmentsChange(e);
          break;
        case 'duration-equipment':
          handleEquipmentDurationChange(e);
          break;
        case 'area-material':
          handleMaterialAreaChange(e);
          // areaOrVolumeOnChange(enteredValue, scopeIndex);
          break;
        case 'material-size':
          handleMaterialSizeChange(e);
          break;
        case 'area-unit':
          handleMaterialAreaUnitChange(e);
          break;
        default:
          console.log('Unhandled input type');
      }
    }
  };

  const handleLaborAreaChange = (e) => {
    const enteredArea = e.target ? e.target.value : e;

    handleLaborInputChange(scopeIndex, index, 'area', enteredArea);
    if (laborCalculationCategory === 'duration') {
      // Clear previous timer if it exists
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }

      // Set a new timer
      timerRef.current = setTimeout(() => {
        calculateLaborDuration({ worker: laborInputValues[scopeIndex]?.[index].workers, area: enteredArea });
      }, 300);
    } else if (laborCalculationCategory === 'workers') {
      // Clear previous timer if it exists
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }

      // Set a new timer
      timerRef.current = setTimeout(() => {
        calculateWorker({ duration: laborInputValues[scopeIndex]?.[index].duration, area: enteredArea });
      }, 300);
    } else {
      console.log('calculation category not yet set');
    }

    // Update the state as the user types
    // setWorkers(e.target.value);
    // handleLaborInputChange(scopeIndex, index, 'area', e.target.value);
  };

  const handleLaborProductivityChange = (e) => {
    const enteredValue = e.target ? e.target.value : e;
    // Update the state as the user types
    // setWorkers(e.target.value);
    handleLaborInputChange(scopeIndex, index, 'productivity', enteredValue);

    if (laborCalculationCategory === 'duration') {
      // Clear previous timer if it exists
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }

      // Set a new timer
      timerRef.current = setTimeout(() => {
        calculateLaborDuration({ worker: laborInputValues[scopeIndex]?.[index].workers, productivity: enteredValue });
      }, 300);
    } else if (laborCalculationCategory === 'workers') {
      // Clear previous timer if it exists
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }

      // Set a new timer
      timerRef.current = setTimeout(() => {
        calculateWorker({ duration: laborInputValues[scopeIndex]?.[index].duration, productivity: enteredValue });
      }, 300);
    } else {
      console.log('calculation category not yet set');
    }
  };

  const handleEstimatedWorkersChange = (e) => {
    // Update the state as the user types
    // setWorkers(e.target.value);
    // handleLaborInputChange(scopeIndex, index, 'workers', e.target.value);
    setLaborCalculationCategory('duration');

    // Clear previous timer if it exists
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    if (e.target.value > 0) {
      // Set a new timer
      timerRef.current = setTimeout(() => {
        calculateLaborDuration({ worker: e.target.value });
      }, 300);
    }
  };

  const handleLaborDurationChange = (e) => {
    // Update the state as the user types
    // setDuration(e.target.value);
    // handleLaborInputChange(scopeIndex, index, 'duration', e.target.value);
    setLaborCalculationCategory('workers');

    // Clear previous timer if it exists
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    if (e.target.value > 0) {
      // Set a new timer
      timerRef.current = setTimeout(() => {
        calculateWorker({ duration: e.target.value });
      }, 300);
    }
  };

  const handleEquipmentAreaChange = (e) => {
    const enteredArea = e.target.value;
    const equipments = equipmentInputValues[scopeIndex]?.[index]?.equipments;

    handleEquipmentInputChange(scopeIndex, index, 'area', enteredArea);

    // if (!equipmentInputValues[scopeIndex]?.[index]?.equipments) {
    //   console.log('Equipments not set');
    //   return;
    // }

    if (equipmentCalculationCategory === 'equipment') {
      // Clear previous timer if it exists
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
      // Set a new timer
      timerRef.current = setTimeout(() => {
        calculateEquipmentDuration({ worker: equipmentInputValues[scopeIndex]?.[index].equipments || numberOfEquipment, area: e.target.value });
      }, 300);
    } else if (equipmentCalculationCategory === 'duration') {
      // Clear previous timer if it exists
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }

      // Set a new timer
      timerRef.current = setTimeout(() => {
        calculateEquipment({ duration: equipmentInputValues[scopeIndex]?.[index].duration || equipmentDuration, area: e.target.value });
      }, 300);
    } else {
      console.log('calculation category not yet set');
    }

    // Update the state as the user types
    // setWorkers(e.target.value);
    // handleLaborInputChange(scopeIndex, index, 'area', e.target.value);
  };

  const handleEquipmentProductivityChange = (e) => {
    // Update the state as the user types
    // setWorkers(e.target.value);
    const enteredValue = e.target ? e.target.value : e;
    handleEquipmentInputChange(scopeIndex, index, 'productivity', enteredValue);

    if (equipmentCalculationCategory === 'duration') {
      // Clear previous timer if it exists
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }

      // Set a new timer
      timerRef.current = setTimeout(() => {
        calculateEquipmentDuration({ worker: equipmentInputValues[scopeIndex]?.[index].equipments, productivity: enteredValue });
      }, 300);
    } else if (equipmentCalculationCategory === 'equipment') {
      // Clear previous timer if it exists
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }

      // Set a new timer
      timerRef.current = setTimeout(() => {
        calculateEquipment({ duration: equipmentInputValues[scopeIndex]?.[index].duration, productivity: enteredValue });
      }, 300);
    } else {
      console.log('calculation category not yet set');
    }
  };

  const handleEstimatedEquipmentsChange = (e) => {
    // Update the state as the user types
    setEquipmentCalculationCategory('duration');

    // Clear previous timer if it exists
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    if (e.target.value > 0) {
      // Set a new timer
      timerRef.current = setTimeout(() => {
        calculateEquipmentDuration({ worker: e.target.value });
      }, 300);
    }
  };

  const handleEquipmentDurationChange = (e) => {
    // Update the state as the user types
    setEquipmentCalculationCategory('equipment');
    // Clear previous timer if it exists
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    if (e.target.value > 0) {
      // Set a new timer
      timerRef.current = setTimeout(() => {
        calculateEquipment({ duration: e.target.value });
      }, 300);
    }
  };

  const handleMaterialAreaChange = (e) => {
    // Update the state as the user types
    // Clear previous timer if it exists
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    if (e.target.value > 0) {
      // Set a new timer
      timerRef.current = setTimeout(() => {
        calculateMaterialQuantity();
      }, 300);
    }
  };

  const handleMaterialSizeChange = (e) => {
    // Update the state as the user types

    // Clear previous timer if it exists
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    if (e.target.value > 0) {
      // Set a new timer
      timerRef.current = setTimeout(() => {
        calculateMaterialQuantity();
      }, 300);
    }
  };

  const handleMaterialAreaUnitChange = (e) => {
    // Update the state as the user types

    // Clear previous timer if it exists
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    if (e.target.value > 0) {
      // Set a new timer
      timerRef.current = setTimeout(() => {
        calculateMaterialQuantity();
      }, 300);
    }
  };

  const handleMaterialTypeChange = (e) => {
    // Update the state as the user types

    // Clear previous timer if it exists
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    if (e.target.value) {
      // Set a new timer
      timerRef.current = setTimeout(() => {
        calculateMaterialQuantity();
      }, 300);
    }
  };

  const handleClose = () => {
    Swal.fire({
      icon: 'question',
      title: 'Are you sure?',
      text: 'You want to stop using the calculator?',
      showCancelButton: true,
      confirmButtonColor: '#23303D',
      cancelButtonColor: '#B42A19',
      confirmButtonText: 'Confirm',
    }).then((result) => {
      if (result.isConfirmed) {
        // clearStates();
        toggleCalculator({ isClosed: true });
      }
    });
  };

  const handleLaborInputChange = (scopeIndex, index, field, value) => {
    setLaborInputValues((prevValues) => {
      const updatedValues = [...prevValues];

      if (!updatedValues[scopeIndex]) {
        updatedValues[scopeIndex] = {};
      }

      if (!updatedValues[scopeIndex][index]) {
        updatedValues[scopeIndex][index] = {};
      }

      updatedValues[scopeIndex][index][field] = value;

      return updatedValues;
    });
  };

  const handleEquipmentInputChange = (scopeIndex, index, field, value) => {
    setEquipmentInputValues((prevValues) => {
      const updatedValues = [...prevValues];

      if (!updatedValues[scopeIndex]) {
        updatedValues[scopeIndex] = {};
      }

      if (!updatedValues[scopeIndex][index]) {
        updatedValues[scopeIndex][index] = {};
      }

      updatedValues[scopeIndex][index][field] = value;

      return updatedValues;
    });
  };

  const handleMaterialInputChange = (scopeIndex, index, field, value) => {
    setMaterialInputValues((prevValues) => {
      const updatedValues = [...prevValues];

      if (!updatedValues[scopeIndex]) {
        updatedValues[scopeIndex] = {};
      }

      if (!updatedValues[scopeIndex][index]) {
        updatedValues[scopeIndex][index] = {};
      }
      if (field === 'input') {
        updatedValues[scopeIndex][index][field] = [value];
      } else {
        updatedValues[scopeIndex][index][field] = value;
      }

      return updatedValues;
    });
  };

  const renderInputFields = () => {
    if (title === 'Labor') {
      if (laborInputValues[scopeIndex]?.[index]?.area === undefined && laborInputValues[scopeIndex]?.[index]?.workers === undefined && laborInputValues[scopeIndex]?.[index]?.duration === undefined) {
        handleLaborInputChange(scopeIndex, index, 'area', '');
        handleLaborInputChange(scopeIndex, index, 'workers', '');
        handleLaborInputChange(scopeIndex, index, 'duration', '');
        handleLaborInputChange(scopeIndex, index, 'phase_id', laborProductivity[0]?.phase_id);
      }

      return (
        <>
          <div className="area-field">
            <CLabel>Productivity</CLabel>
            <div className="input-wrapper">
              <CInput
                value={laborInputValues[scopeIndex]?.[index]?.productivity}
                onChange={(e) => handleInputChange(e, 'productivity-labor')}
                type="text"
                className={`area-textfield${productivityLaborValid ? '' : ' invalid-input'}`}
                step="any"
                invalid={!productivityLaborValid}
                placeholder={laborProductivity[0]?.no_productivity === 1 ? 'Provide your data' : ''}
                onFocus={() => setIsFocusedProductivityLabor(true)}
                onBlur={() => {
                  setTimeout(() => setIsFocusedProductivityLabor(false), 200);
                }}
              />
              {/* <CInput value={laborInputValues[scopeIndex]?.[index]?.productivity_unit} onChange={(e) => handleLaborInputChange(scopeIndex, index, 'productivity_unit', e.target.value)} type="alphanumeric" className="area-dropdown" disabled /> */}
              {/* <CSelect className="area-dropdown ">
                <option>sqm/h</option>
                <option>sqft/h</option>
                <option>sqcm/h</option>
              </CSelect> */}
              <CSelect
                className="area-dropdown"
                value={laborInputValues[scopeIndex]?.[index]?.duration_unit_id}
                onChange={(e) => {
                  handleLaborInputChange(scopeIndex, index, 'productivity_unit_id', parseInt(e.target.value));
                  handleLaborInputChange(scopeIndex, index, 'duration_unit_id', parseInt(e.target.value));
                  // const productivityUnit = e.target.value.split('/')[1].trim();
                  // handleLaborInputChange(scopeIndex, index, 'duration_unit', productivityUnit === 'hr' ? 'h' : 'day');
                }}
              >
                <option value={36}>{`${units[laborInputValues[scopeIndex]?.[index]?.area_unit_id]}/ hr`}</option>
                <option value={37}>{`${units[laborInputValues[scopeIndex]?.[index]?.area_unit_id]}/ day`}</option>
              </CSelect>
            </div>
            {!productivityLaborValid && <CLabel style={{ color: '#b42a19', marginBottom: 0 }}>Invalid input. Enter a positive number. </CLabel>}
            {/* TODO: Change the productivityResults to the real productivity history */}
            {isFocusedProductivityLabor && productivityLaborValid && (
              <div className="area-search-results">
                <div style={{ padding: '5px 10px', color: '#757070' }}>Historical data</div>
                {laborInputValues[scopeIndex]?.[index]?.productivity_history?.length > 0 ? (
                  (laborInputValues[scopeIndex]?.[index]?.productivity && String(laborInputValues[scopeIndex]?.[index]?.productivity).trim() !== '' && Number(laborInputValues[scopeIndex]?.[index]?.productivity) !== 0
                    ? laborInputValues[scopeIndex]?.[index]?.productivity_history.filter((result) => String(result).includes(String(laborInputValues[scopeIndex]?.[index]?.productivity)))
                    : laborInputValues[scopeIndex]?.[index]?.productivity_history
                  ).map((result, i) => (
                    <div
                      className="area-search-result"
                      key={`${i}-${result}`}
                      onClick={() => {
                        handleInputChange(result, 'productivity-labor');
                        setIsFocusedProductivityLabor(!isFocusedProductivityLabor);
                      }}
                    >
                      {result}
                    </div>
                  ))
                ) : (
                  <div style={{ padding: '5px 10px' }}>No result</div>
                )}
              </div>
            )}
          </div>
          <div className="area-field">
            <CLabel>Area</CLabel>
            <div className="input-wrapper">
              <CInput value={laborInputValues[scopeIndex]?.[index]?.area} onInput={(e) => handleInputChange(e, 'area-labor')} type="text" className={`area-textfield${areaLaborValid ? '' : ' invalid-input'}`} step="any" invalid={!areaLaborValid} />
              {/* <CInput value={laborInputValues[scopeIndex]?.[index]?.area_unit} onChange={(e) => handleLaborInputChange(scopeIndex, index, 'area_unit', e.target.value)} type="alphanumeric" className="area-dropdown" disabled /> */}
              <CSelect
                className="area-dropdown"
                value={laborInputValues[scopeIndex]?.[index]?.area_unit_id}
                onChange={(e) => {
                  handleLaborInputChange(scopeIndex, index, 'area_unit_id', parseInt(e.target.value));
                  areaOrVolumeUnitOnChange(e.target.value, scopeIndex);
                }}
              >
                {Object.keys(units).map((unitId) => (
                  <option key={`unit-key-${unitId}`} value={unitId}>
                    {units[unitId]}
                  </option>
                ))}
              </CSelect>
            </div>
            {!areaLaborValid && <CLabel style={{ color: '#b42a19', marginBottom: 0 }}>Invalid input. Enter a positive number. </CLabel>}
          </div>

          <div className="area-field">
            <CTooltip content="No. of estimated workers = Area / ( Productivity * Duration)" placement="left-end" color="white" style={{ padding: '0', fontSize: '8px' }}>
              <CLabel style={{ textDecoration: 'underline' }}>No. of Estimated Workers</CLabel>
            </CTooltip>
            <CInput value={laborInputValues[scopeIndex]?.[index]?.workers} onChange={(e) => handleInputChange(e, 'workers-labor')} type="text" className={`estimated-textfield${workersLaborValid ? '' : ' invalid-input'}`} step="any" invalid={!workersLaborValid} />
            {!workersLaborValid && <CLabel style={{ color: '#b42a19', marginBottom: 0 }}>Invalid input. Enter a positive number. </CLabel>}
          </div>
          <div className="area-field">
            <CTooltip content="Duration = Area/(Productivity * No. of Estimated Workers)" placement="left-end" color="white" style={{ padding: '0', fontSize: '8px' }}>
              <span>
                <CLabel style={{ textDecoration: 'underline' }}>Duration</CLabel>
              </span>
            </CTooltip>
            <div className="input-wrapper">
              <CInput value={laborInputValues[scopeIndex]?.[index]?.duration} onChange={(e) => handleInputChange(e, 'duration-labor')} type="number" className={`area-textfield${durationLaborValid ? '' : ' invalid-input'}`} step="any" invalid={!durationLaborValid} onKeyDown={preventInvalidKeys} onWheel={blurOnWheel} onPaste={preventInvalidPaste} />
              <CInput
                value={laborInputValues[scopeIndex]?.[index]?.duration_unit_id === undefined || laborInputValues[scopeIndex]?.[index]?.duration_unit_id === 36 ? 'hour' : 'day'}
                onChange={(e) => handleLaborInputChange(scopeIndex, index, 'duration_unit', e.target.value)}
                type="alphanumeric"
                className="area-dropdown"
                disabled
              />
              {/* <CSelect className="area-dropdown ">
                <option>day</option>
                <option>hour</option>
              </CSelect> */}
            </div>
            {!durationLaborValid && <CLabel style={{ color: '#b42a19', marginBottom: 0 }}>Invalid input. Enter a positive number. </CLabel>}
          </div>
        </>
      );
    } else if (title === 'Equipment') {
      if (equipmentInputValues[scopeIndex]?.[index]?.area === undefined && equipmentInputValues[scopeIndex]?.[index]?.workers === undefined && equipmentInputValues[scopeIndex]?.[index]?.duration === undefined) {
        handleEquipmentInputChange(scopeIndex, index, 'area', '');
        handleEquipmentInputChange(scopeIndex, index, 'equipments', '');
        handleEquipmentInputChange(scopeIndex, index, 'duration', '');
        handleEquipmentInputChange(scopeIndex, index, 'phase_id', equipmentProductivity[0]?.phase_id);
      }
      return (
        <>
          <div className="area-field">
            <div style={{ display: 'flex', alignItems: 'flex-start' }}>
              <CLabel>Productivity</CLabel>
            </div>
            <div className="input-wrapper">
              <CInput
                value={equipmentInputValues[scopeIndex]?.[index]?.productivity}
                onChange={(e) => handleInputChange(e, 'productivity-equipment')}
                type="text"
                className={`area-textfield${productivityEquipmentValid ? '' : ' invalid-input'}`}
                step="any"
                invalid={!productivityEquipmentValid}
                placeholder={equipmentProductivity[0]?.no_productivity === 1 || equipmentInputValues[scopeIndex]?.[index]?.productivity === '' ? 'Provide your data' : ''}
                onFocus={() => {
                  setIsFocusedProductivityEquipment(true);
                }}
                onBlur={() => {
                  setTimeout(() => setIsFocusedProductivityEquipment(false), 100);
                }}
              />
              {/* <CInput value={equipmentInputValues[scopeIndex]?.[index]?.productivity_unit} onChange={(e) => handleEquipmentInputChange(scopeIndex, index, 'productivity_unit', e.target.value)} type="alphanumeric" className="area-dropdown" disabled /> */}
              {/* <CSelect className="area-dropdown ">
                <option value={equipmentInputValues[scopeIndex]?.[index]?.area_unit}>{`${units[equipmentInputValues[scopeIndex]?.[index]?.area_unit_id]}/ hr`}</option>
                <option value={equipmentInputValues[scopeIndex]?.[index]?.area_unit}>{`${units[equipmentInputValues[scopeIndex]?.[index]?.area_unit_id]}/ day`}</option>
              </CSelect> */}
              <CSelect
                className="area-dropdown"
                value={equipmentInputValues[scopeIndex]?.[index]?.duration_unit_id}
                onChange={(e) => {
                  handleEquipmentInputChange(scopeIndex, index, 'productivity_unit_id', parseInt(e.target.value));
                  handleEquipmentInputChange(scopeIndex, index, 'duration_unit_id', parseInt(e.target.value));
                  // const productivityUnit = e.target.value.split('/')[1].trim();
                  // handleEquipmentInputChange(scopeIndex, index, 'duration_unit', productivityUnit === 'hr' ? 'h' : 'day');
                }}
              >
                <option value={36}>{`${units[equipmentInputValues[scopeIndex]?.[index]?.area_unit_id]}/ hr`}</option>
                <option value={37}>{`${units[equipmentInputValues[scopeIndex]?.[index]?.area_unit_id]}/ day`}</option>
              </CSelect>
            </div>
            {!productivityEquipmentValid && <CLabel style={{ color: '#b42a19', marginBottom: 0 }}>Invalid input. Enter a positive number. </CLabel>}
            {/* TODO: Change the productivityResults to the real productivity history */}
            {isFocusedProductivityEquipment && productivityEquipmentValid && (
              <div className="area-search-results">
                <div style={{ padding: '5px 10px', color: '#757070' }}>Historical data</div>
                {equipmentInputValues[scopeIndex]?.[index]?.productivity_history.length > 0 ? (
                  (equipmentInputValues[scopeIndex]?.[index]?.productivity && String(equipmentInputValues[scopeIndex]?.[index]?.productivity).trim() !== '' && Number(equipmentInputValues[scopeIndex]?.[index]?.productivity) !== 0
                    ? equipmentInputValues[scopeIndex]?.[index]?.productivity_history.filter((result) => String(result).includes(String(equipmentInputValues[scopeIndex]?.[index]?.productivity)))
                    : equipmentInputValues[scopeIndex]?.[index]?.productivity_history
                  ).map((result, i) => (
                    <div
                      className="area-search-result"
                      key={`${i}-${result}`}
                      onClick={() => {
                        handleInputChange(result, 'productivity-equipment');
                        setIsFocusedProductivityEquipment(!isFocusedProductivityEquipment);
                      }}
                    >
                      {result}
                    </div>
                  ))
                ) : (
                  <div style={{ padding: '5px 10px' }}>No result</div>
                )}
              </div>
            )}
          </div>
          <div className="area-field">
            {equipmentInputValues[scopeIndex]?.[index]?.area_unit_id < 16 ? <CLabel>Area</CLabel> : <CLabel>Volume</CLabel>}
            <div className="input-wrapper">
              <CInput value={equipmentInputValues[scopeIndex]?.[index]?.area} onChange={(e) => handleInputChange(e, 'area-equipment')} type="text" className={`area-textfield${areaEquipmentValid ? '' : ' invalid-input'}`} step="any" invalid={!areaEquipmentValid} />
              {/* <CInput value={equipmentInputValues[scopeIndex]?.[index]?.area_unit} onChange={(e) => handleEquipmentInputChange(scopeIndex, index, 'area_unit', e.target.value)} type="alphanumeric" className="area-dropdown" disabled /> */}
              <CSelect
                className="area-dropdown"
                value={equipmentInputValues[scopeIndex]?.[index]?.area_unit_id}
                onChange={(e) => {
                  handleEquipmentInputChange(scopeIndex, index, 'area_unit_id', parseInt(e.target.value));
                  areaOrVolumeUnitOnChange(e.target.value, scopeIndex);
                }}
              >
                {Object.keys(units).map((unitId) => (
                  <option key={`unit-key-${unitId}`} value={unitId}>
                    {units[unitId]}
                  </option>
                ))}
              </CSelect>
            </div>
            {!areaEquipmentValid && <CLabel style={{ color: '#b42a19', marginBottom: 0 }}>Invalid input. Enter a positive number. </CLabel>}
          </div>
          <div className="area-field">
            <CTooltip content="No. of equipment= Area / (Productivity * Duration)" placement="left-end" color="white" style={{ padding: '0', fontSize: '8px' }}>
              <CLabel style={{ textDecoration: 'underline' }}>No. of Equipment</CLabel>
            </CTooltip>
            <CInput value={equipmentInputValues[scopeIndex]?.[index]?.equipments} onChange={(e) => handleInputChange(e, 'equipments-equipment')} type="text" className={`estimated-textfield${workersEquipmentValid ? '' : ' invalid-input'}`} step="any" invalid={!workersEquipmentValid} />
            {!workersEquipmentValid && <CLabel style={{ color: '#b42a19', marginBottom: 0 }}>Invalid input. Enter a positive number. </CLabel>}
          </div>
          <div className="area-field">
            <CTooltip content="Duration = Area/(Productivity * No. of Equipment)" placement="left-end" color="white" style={{ padding: '0', fontSize: '8px' }}>
              <CLabel style={{ textDecoration: 'underline' }}>Duration</CLabel>
            </CTooltip>

            <div className="input-wrapper">
              <CInput value={equipmentInputValues[scopeIndex]?.[index]?.duration} onChange={(e) => handleInputChange(e, 'duration-equipment')} type="number" className={`area-textfield${durationEquipmentValid ? '' : ' invalid-input'}`} step="any" invalid={!durationEquipmentValid} onKeyDown={preventInvalidKeys} onWheel={blurOnWheel} onPaste={preventInvalidPaste} />
              <CInput value={equipmentInputValues[scopeIndex]?.[index]?.duration_unit_id === 36 ? 'hour' : 'day'} onChange={(e) => handleEquipmentInputChange(scopeIndex, index, 'duration_unit', e.target.value)} type="alphanumeric" className="area-dropdown" disabled />
              {/* <CSelect className="area-dropdown ">
                <option>day</option>
                <option>hour</option>
              </CSelect> */}
            </div>
            {!durationEquipmentValid && <CLabel style={{ color: '#b42a19', marginBottom: 0 }}>Invalid input. Enter a positive number. </CLabel>}
          </div>
        </>
      );
    } else if (title === 'Material') {
      // if (materialInputValues[scopeIndex]?.[index]?.area === undefined && materialInputValues[scopeIndex]?.[index]?.size === undefined && materialInputValues[scopeIndex]?.[index]?.materialQuantity === undefined) {
      //   handleMaterialInputChange(scopeIndex, index, 'area', '');
      //   handleMaterialInputChange(scopeIndex, index, 'size', '');
      //   handleMaterialInputChange(scopeIndex, index, 'materialQuantity', '');
      // }
      return (
        <>
          {' '}
          <div className="area-field">
            <CLabel>Material Name</CLabel>
            {/* <h5>{materialName.label || materialName}</h5> */}
            <h5>{materialInputValues[scopeIndex]?.[index]?.material_name}</h5>
          </div>
          {materialInputValues &&
            materialInputValues[scopeIndex]?.[index]?.input?.map((item, i) => (
              <>
                {item?.map((inputField, j) => (
                  <div className="area-field" key={`area-field-${j}`}>
                    <CLabel>{inputField.label.charAt(0).toUpperCase() + inputField.label.slice(1)}</CLabel>
                    {inputField.options ? (
                      inputField.label === 'Size' ? (
                        <div className="input-wrapper">
                          <CSelect value={materialInputValues[scopeIndex]?.[index]?.size} className="material-dropdown" onChange={(e) => handleInputChange(e, 'material-size')}>
                            <option value="">Select a size</option> {/* Default option */}
                            {inputField.options.map((option, k) => (
                              <option key={k} value={option.id}>
                                {`${option.unit_size_value} ${option.unit_size_name}`}
                              </option>
                            ))}
                          </CSelect>
                        </div>
                      ) : inputField.label === 'No of Pipes' ? (
                        <>
                          <div className="input-wrapper">
                            {' '}
                            <CInput value={materialInputValues[scopeIndex]?.[index]?.no_of_pipes} type="number" className="material-dropdown" placeholder={`Enter ${inputField.label}`} onChange={(e) => handleInputChange(e, 'no_of_pipes')} onKeyDown={preventInvalidKeys} onWheel={blurOnWheel} onPaste={preventInvalidPaste} />{' '}
                          </div>
                          {!noOfPipesMaterialValid && <CLabel style={{ color: '#b42a19', marginBottom: 0 }}>Invalid input. Enter a positive number. </CLabel>}
                        </>
                      ) : inputField.label === 'No of Columns' ? (
                        <>
                          <div className="input-wrapper">
                            {' '}
                            <CInput value={materialInputValues[scopeIndex]?.[index]?.no_of_columns} type="number" className="material-dropdown" placeholder={`Enter ${inputField.label}`} onChange={(e) => handleInputChange(e, 'no_of_columns')}  onKeyDown={preventInvalidKeys} onWheel={blurOnWheel} onPaste={preventInvalidPaste}/>{' '}
                          </div>
                          {!noOfColumnsMaterialValid && <CLabel style={{ color: '#b42a19', marginBottom: 0 }}>Invalid input. Enter a positive number. </CLabel>}
                        </>
                      ) : (
                        <div className="input-wrapper">
                          {/* <CSelect id={`${inputField.label}-${j}`} key={`${inputField.label}-${j}`} value={materialInputValues[scopeIndex]?.[index]?.[inputField.label.toLowerCase()]} className="material-dropdown" onChange={(e) => handleInputChange(e, inputField.label.toLowerCase())}> */}
                          <CSelect
                            id={`${inputField.label}-${j}`}
                            key={`${inputField.label}-${j}`}
                            value={
                              inputField.label === 'Category'
                                ? materialInputValues[scopeIndex]?.[index]?.['category']
                                : inputField.options.find((option) => {
                                    if (typeof option === 'string') {
                                      if (inputField.label === 'Board size') {
                                        return option.includes(materialInputValues[scopeIndex]?.[index]?.[inputField.label.toLowerCase().replace(' ', '_')]);
                                      }
                                      return option.includes(materialInputValues[scopeIndex]?.[index]?.[inputField.label.toLowerCase()]);
                                    } else {
                                      return option === materialInputValues[scopeIndex]?.[index]?.[inputField.label.toLowerCase()];
                                    }
                                  })
                            }
                            className="material-dropdown"
                            onChange={(e) => handleInputChange(e, inputField.label.toLowerCase())}
                          >
                            <option value="">{`Select ${inputField.label}`}</option> {/* Default option */}
                            {inputField.options.map((option, k) => (
                              <option key={k} value={inputField.label === 'Category' ? option.unit : option}>
                                {inputField.label === 'Category' ? option.unit : option}
                              </option>
                            ))}
                          </CSelect>
                        </div>
                      )
                    ) : inputField.label === 'Area' ? (
                      <div>
                        <div className="input-wrapper">
                          <CInput value={materialInputValues[scopeIndex]?.[index]?.area} type="number" className="area-textfield" placeholder={`Enter ${inputField.label}`} onChange={(e) => handleInputChange(e, 'area-material')} onKeyDown={preventInvalidKeys} onWheel={blurOnWheel} onPaste={preventInvalidPaste} />

                          <CSelect className="area-dropdown" onChange={(e) => handleInputChange(e, 'area-unit')}>
                            {inputField.unitOptions.map((option, k) => (
                              <option key={k} value={option.unit_id}>
                                {option.unit}
                              </option>
                            ))}
                          </CSelect>
                        </div>
                        {!areaMaterialValid && <CLabel style={{ color: '#b42a19', marginBottom: 0 }}>Invalid input. Enter a positive number. </CLabel>}
                      </div>
                    ) : (
                      <div>
                        <div className="input-wrapper">
                          <CInput value={materialInputValues[scopeIndex]?.[index]?.height} type="number" className="area-textfield" placeholder={`Enter ${inputField.label}`} onChange={(e) => handleInputChange(e, 'height')} onKeyDown={preventInvalidKeys} onWheel={blurOnWheel} onPaste={preventInvalidPaste} />

                          <CSelect className="area-dropdown" onChange={(e) => handleInputChange(e, 'height-unit')}>
                            {inputField.unitOptions.map((option, k) => (
                              <option key={k} value={option.unit_id}>
                                {option.unit}
                              </option>
                            ))}
                          </CSelect>
                        </div>
                        {!areaMaterialValid && <CLabel style={{ color: '#b42a19', marginBottom: 0 }}>Invalid input. Enter a positive number. </CLabel>}
                      </div>
                    )}
                  </div>
                ))}
              </>
            ))}
          <div className="area-field">
            {/* <CTooltip content="Added 5% allowancess" placement="right-end" color="white" style={{ padding: '0', fontSize: '8px' }}>
              <CLabel style={{ textDecoration: 'underline' }}>No. of Materials</CLabel>
            </CTooltip>
            <CInput value={materialInputValues[scopeIndex]?.[index]?.materialQuantity} type="number" className="estimated-textfield" style={{ background: 'white' }} disabled /> */}
          </div>
          <div className="area-field">
            <CTooltip content="Added 5% allowance" placement="left-end" color="white" style={{ padding: '0', fontSize: '8px' }}>
              <CLabel style={{ textDecoration: 'underline' }}>No. of Materials</CLabel>
            </CTooltip>
            <CInput value={materialInputValues[scopeIndex]?.[index]?.materialQuantity} type="number" className="estimated-textfield" style={{ background: 'white'  }} onKeyDown={preventInvalidKeys} onWheel={blurOnWheel} onPaste={preventInvalidPaste} disabled />
          </div>
        </>
      );
    }
  };

  return (
    <div className={`calculator ${isOpen ? 'open' : ''}`}>
      <div className="close-button">
        <CButton onClick={handleClose} style={{ width: '20px', height: '20px', padding: 0 }}>
          <img src={close} style={{ width: '100%', height: '100%' }} />
        </CButton>
      </div>
      <div className="calculator-title">
        {/* Calculator Title */}
        <h5 className="calculation-title">{title} Calculation</h5>
      </div>
      <div className="sow-group">
        {/* SOW */}
        <h2 className="sow-name">{sow}</h2>
        {/*  */}
        <h3 className="work-name">{work_item}</h3>
      </div>
      <div className="description">
        <p className="description-text">{description}</p>
      </div>

      {renderInputFields()}
      <div className="button-container">
        <CButton className="apply-button" style={{ backgroundColor: buttonColor, color: fontColor }} onClick={handleApply} disabled={areFieldsFilled}>
          Apply
        </CButton>
      </div>
    </div>
  );
};

export default RiskCalculator;
