import React, { useState, useRef } from 'react';
import { CModal, CModalHeader, CModalBody, CTooltip, CButton, CDropdown, CDropdownMenu, CDropdownItem, CDropdownToggle, CInput } from '@coreui/react';
import { IoSend } from 'react-icons/io5';
import { FaFileImage, FaFileExcel, FaReply, FaPencilAlt, FaEllipsisV, FaTrashAlt, FaPhotoVideo, FaFilePdf, FaFile } from 'react-icons/fa';
import { MdUploadFile } from 'react-icons/md';
import { GetComment, AddComment, GetReply, DeleteComment, UpdateComment, GetHistoricalComment } from 'src/request/Reports';
import './modal.css';
const ModalComments = ({ isModalOpen = false, onClose, title = 'Comments', reportData = [], date = '', type = 1 }) => {
  // Type - 1 Current Week
  // Type - 2 Historical Report
  // Type - 3 Two Weeks Ahead
  const [messages, setMessages] = useState([]);
  const [replyParentID, setReplyParentID] = useState(null);
  const [showReplies, setShowReplies] = useState({});
  const [replyData, setReplyData] = useState({});
  const commentRefs = useRef({});
  const replyInputRef = useRef(null);
  const psow_id = reportData?.psow_id;
  const isInit = useRef(true);
  const [selectedComment, setSelectedComment] = useState(null);
  const [showQuote, setShowQuote] = useState(false);
  const [quoteData, setQuoteData] = useState([]);
  const user_id = localStorage.getItem('user_id') || null;
  const [isLoading, setIsLoading] = useState(false);
  const [isEditComment, setIsEditComment] = useState([]);
  const [editUploadedFiles, setEditUploadedFiles] = useState([]);
  const [removeAttachments, setRemoveAttachments] = useState([]);

  const convertDateFormat = (dateString) => {
    const [month, day, year] = dateString.split('/');
    return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
  };

  const convertedType = type === 3 ? 2 : type;
  const [startDate, endDate] = date?.split(' - ').map(convertDateFormat);
  const fetchCommentData = () => {
    setIsLoading(true);

    if (type === 1 || type === 3) {
      GetComment(psow_id, convertedType, startDate, endDate)
        .then((res) => {
          if (res.data.status === 200) {
            setMessages(res?.data?.data);
          }
        })
        .finally(() => setIsLoading(false));
    } else if (type === 2) {
      GetHistoricalComment(psow_id, startDate, endDate)
        .then((res) => {
          if (res.data.status === 200) {
            setMessages(res?.data?.data);
          }
        })
        .finally(setIsLoading(false));
    }
  };

  const fetchReplies = ({ commentID, toggle }) => {
    if (toggle) {
      setShowReplies((prev) => ({ ...prev, [commentID]: !prev[commentID] }));
    }
    GetReply(commentID).then((res) => {
      if (res?.data?.status === 200) {
        setReplyData((prev) => ({ ...prev, [commentID]: res?.data?.data }));
      }
    });
  };

  const handleDelete = ({ comment_id, isReply, rootParentID }) => {
    DeleteComment({ comment_id }).then((res) => {
      if (res.data.status === 200) {
        if (isReply) {
          fetchReplies({ commentID: rootParentID, toggle: false });
        }
        fetchCommentData();
      }
    });
  };

  const handleEdit = ({ commentID }) => {
    setIsEditComment((prevData) => ({ ...prevData, [commentID]: !prevData[commentID] }));
  };

  const ChatMessageSkeleton = ({ align = 'start' }) => {
    return (
      <div className={`no-scroll d-flex justify-content-${align} mb-3`}>
        <div className="chat-bubble rounded p-3 border border-2 bg-light shimmer" style={{ maxWidth: '70%', minWidth: '60%', margin: align === 'start' ? '0 10px 0 0' : '0 0 0 10px' }}>
          <div className="d-flex justify-content-between align-items-center mb-2">
            <div className="bg-secondary rounded shimmer" style={{ width: '25%', height: '12px' }}></div>
            <div className="d-flex gap-2 align-items-center">
              {[...Array(2)].map((_, i) => (
                <div key={i} className="bg-secondary rounded-circle shimmer" style={{ width: '15px', height: '15px' }}></div>
              ))}
            </div>
          </div>
          <div className="bg-secondary rounded shimmer mb-2" style={{ width: '50%', height: '10px' }}></div>
          <div className="bg-secondary rounded shimmer mb-2" style={{ width: '100%', height: '40px' }}></div>
          <div className="bg-secondary rounded shimmer mt-2" style={{ width: '75%', height: '50px' }}></div>
          <div className="d-flex justify-content-end mt-2">
            <div className="bg-secondary rounded shimmer" style={{ width: '25%', height: '20px' }}></div>
          </div>
        </div>
      </div>
    );
  };

  const ChatMessage = ({ message, isReply, rootParentID }) => {
    const fullname = user_id == message?.user_id ? 'You' : message?.user_name;
    const commentID = message?.comment_id;
    const editFileInputRef = useRef(null);
    const [content, setContent] = useState(message?.content || '');
    const handleReplyClick = () => {
      setShowQuote(true);
      setQuoteData(message);
      setSelectedComment(commentID);
      setReplyParentID(rootParentID ?? commentID);
      setTimeout(() => {
        if (replyInputRef.current) {
          replyInputRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
        }
      }, 100);
    };
    if (!commentRefs.current[commentID]) {
      commentRefs.current[commentID] = React.createRef();
    }
    const handleDeleteClick = () => {
      handleDelete({ comment_id: message.comment_id, isReply, rootParentID });
    };
    const handleEditClick = () => {
      handleEdit({ commentID: message.comment_id, isReply, rootParentID });
      setShowReplies((prev) => ({ ...prev, [commentID]: false }));
    };
    const handleCancelEdit = () => {
      setIsEditComment((prevData) => ({ ...prevData, [commentID]: !prevData[commentID] }));
    };
    const handleRemoveAttachment = (attachmentID, commentID) => {
      if (isReply) {
        setReplyData((prevReplies) => {
          if (!prevReplies || !prevReplies[rootParentID]) return prevReplies;
          return {
            ...prevReplies,
            [rootParentID]: prevReplies[rootParentID].map((msg) => ({
              ...msg,
              attachments: msg.attachments.filter((att) => att.id !== attachmentID),
            })),
          };
        });
      } else {
        setMessages((prevMessages) => prevMessages.map((msg) => (msg.comment_id === commentID ? { ...msg, attachments: msg.attachments.filter((att) => att.id !== attachmentID) } : msg)));
      }
      setRemoveAttachments((prevData) => ({
        ...prevData,
        [message?.comment_id]: [...(prevData[message?.comment_id] || []), { id: attachmentID }],
      }));
    };
    const handleContentChange = (e) => {
      setContent(e.target.value);
    };
    const handleEditUploadFile = (event) => {
      const files = Array.from(event.target.files);
      if (isReply) {
        setReplyData((prevReplies) => {
          if (!prevReplies || !prevReplies[rootParentID]) return prevReplies;
          return {
            ...prevReplies,
            [rootParentID]: prevReplies[rootParentID].map((msg) => {
              if (msg.comment_id === commentID) {
                const existingAttachments = msg.attachments || [];
                const newAttachments = files.map((file, index) => ({
                  id: `temp-${commentID}-${index}`,
                  filename: file.name,
                  attachment_type_id: 1,
                }));
                return { ...msg, attachments: [...existingAttachments, ...newAttachments] };
              }
              return msg;
            }),
          };
        });
      } else {
        setMessages((prevMessages) =>
          prevMessages.map((msg) => {
            if (msg.comment_id === commentID) {
              const existingAttachments = msg.attachments || [];
              const newAttachments = files.map((file, index) => ({
                id: `temp-${commentID}-${index}`,
                filename: file.name,
                attachment_type_id: 1,
              }));
              return { ...msg, attachments: [...existingAttachments, ...newAttachments] };
            }
            return msg;
          }),
        );
      }

      setEditUploadedFiles((prevFiles) => {
        const existingFiles = prevFiles[commentID] || [];
        const uniqueFiles = files.filter((file) => !existingFiles.some((existingFile) => existingFile.name === file.name));
        return {
          ...prevFiles,
          [commentID]: [...existingFiles, ...uniqueFiles],
        };
      });
    };
    const handleSubmitEdit = () => {
      const formData = new FormData();
      const attachmentIDs = removeAttachments[commentID] || [];

      formData.append('comment_id', commentID);
      formData.append('new_content', content);
      formData.append('old_records', JSON.stringify(attachmentIDs));
      editUploadedFiles[commentID]?.forEach((file) => {
        formData.append('attachment_files', file);
      });
      UpdateComment(formData).then((res) => {
        if (res.data.status === 200) {
          if (isReply) {
            fetchReplies({ commentID: rootParentID, toggle: false });
          } else {
            fetchCommentData();
          }
          setRemoveAttachments((prevData) => {
            const newData = { ...prevData };
            delete newData[commentID];
            return newData;
          });
          setEditUploadedFiles((prevFiles) => {
            const newFiles = { ...prevFiles };
            delete newFiles[commentID];
            return newFiles;
          });
          handleEditClick();
        }
      });
    };
    return (
      <div ref={commentRefs.current[commentID]} className={`chat-message d-flex justify-content-${message?.user_id == user_id ? 'end' : 'start'} mb-3`}>
        <div className="chat-bubble rounded p-3 border border-2" style={{ maxWidth: '70%', fontSize: '12px', minWidth: '60%' }}>
          <div className="d-flex flex-column mb-2">
            <div className="d-flex justify-content-between align-items-center">
              <div className="font-weight-bold">{fullname}</div>
              {type != 2 && (
                <div className="d-flex gap-2 align-items-center">
                  <CTooltip content="Add a reply">
                    <FaReply className="mx-2" size={15} style={{ cursor: 'pointer' }} onClick={handleReplyClick} />
                  </CTooltip>
                  {message?.user_id == user_id && (
                    <CDropdown direction="left" className="d-inline">
                      <CDropdownToggle caret={false} className="p-0 border-0 bg-transparent">
                        <FaEllipsisV className="mr-2" size={15} style={{ cursor: 'pointer' }} />
                      </CDropdownToggle>
                      <CDropdownMenu placement="bottom-end">
                        <CDropdownItem onClick={handleEditClick}>
                          <FaPencilAlt className="mr-2 aimhi-light" /> Edit
                        </CDropdownItem>
                        <CDropdownItem onClick={handleDeleteClick} className="aimhi-light">
                          <FaTrashAlt className="mr-2" /> Delete
                        </CDropdownItem>
                      </CDropdownMenu>
                    </CDropdown>
                  )}
                </div>
              )}
            </div>
            {message?.comment_type === 'report_plans' && (
              <>
                <small className="bg-aimhi-textlight-neutral text-white px-2 py-0.5 rounded-full text-xs font-medium inline-flex items-center align-self-start" style={{borderRadius: '15px'}}>Plans</small>
              </>
            )}
            <div className="text-muted" style={{ fontSize: '10px' }}>
              {message.created_at}
            </div>
          </div>

          {isEditComment[commentID] ? (
            <>
              <textarea className="form-control shadow-sm flex-grow-1" value={content} onChange={handleContentChange} />
              <div className="d-flex justify-content-end align-item-center mt-3">
                <input type="file" multiple accept=".jpg,.jpeg,.png,.pdf,.docx,.xlsx,.mp4" onChange={handleEditUploadFile} style={{ display: 'none' }} ref={editFileInputRef} />
                <CTooltip content="Upload a file">
                  <MdUploadFile role="button" onClick={() => editFileInputRef.current.click()} style={{ fontSize: '30px', color: '#6c757d', marginRight: '8px', cursor: 'pointer' }} />
                </CTooltip>
              </div>
            </>
          ) : (
            <>{message?.content}</>
          )}
          {message?.attachments?.length > 0 && (
            <div className="d-flex flex-column align-items-start mt-2">
              <AttachmentView attachmentData={message?.attachments} imageUrl={message?.imageUrl} isEditing={isEditComment[commentID]} onRemoveAttachment={(id) => handleRemoveAttachment(id, commentID)} />
            </div>
          )}
          {(!isReply && message?.reply_count > 0) || isEditComment[commentID] ? (
            <>
              {isEditComment[commentID] ? (
                <>
                  <div className="d-flex flex-row align-items-center justify-content-end mt-3">
                    <CButton className="mr-3" color="aimhi-textlight-neutral" size="md" onClick={handleSubmitEdit}>
                      Update
                    </CButton>
                    <CButton color="aimhi-light" size="md" onClick={handleCancelEdit}>
                      Cancel
                    </CButton>
                  </div>
                </>
              ) : (
                <>
                  <div className="d-flex justify-content-end mt-3">
                    <CButton color="aimhi-textlight-neutral" size="sm" onClick={() => fetchReplies({ commentID: commentID, toggle: true })}>
                      <FaReply className="me-1" /> {showReplies[commentID] ? 'Hide Replies' : `View Replies (${message?.reply_count})`}
                    </CButton>
                  </div>
                </>
              )}
            </>
          ) : null}
          {showReplies[commentID] && message?.reply_count > 0 && (
            <>
              <hr />
              <ReplyArea replies={replyData[commentID]} rootParentID={commentID} />
            </>
          )}
        </div>
      </div>
    );
  };

  const ReplyArea = ({ replies, rootParentID }) => {
    const ReplyQuote = ({ message, messageID }) => {
      const fullname = user_id == message?.user_id ? 'You' : message?.user_name;
      const handleQuoteClick = () => {
        const ref = commentRefs.current[message?.comment_id];
        if (ref?.current) {
          ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      };
      return (
        <>
          <div className={`chat-message d-flex justify-content-${messageID == user_id ? 'end' : 'start'}`} onClick={handleQuoteClick} role="button">
            <div
              className="chat-bubble p-3 border border-2 border-bottom-0 bg-light"
              style={{
                maxWidth: '70%',
                fontSize: '12px',
                minWidth: '60%',
                borderRadius: '20px 20px 0 0',
              }}
            >
              <div className="d-flex flex-column">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="font-weight-bold" style={{ color: 'rgba(0, 0, 0, 0.6)' }}>
                    {fullname}
                  </div>
                </div>
              </div>
              <i style={{ color: 'rgba(0, 0, 0, 0.6)' }}>{message?.content}</i>
            </div>
          </div>
        </>
      );
    };

    return (
      <div className="chat-replies mt-3 d-flex flex-column gap-2" style={{ maxHeight: '300px', overflowY: 'auto', paddingRight: '5px' }}>
        {replies?.map((reply) => {
          const quotedMessage = replies.find((msg) => msg.comment_id === reply.quote_id);
          return (
            <div key={reply?.comment_id}>
              {reply.quote_id && quotedMessage && <ReplyQuote message={quotedMessage} messageID={reply?.user_id} />}
              <ChatMessage message={reply} isReply={true} rootParentID={rootParentID} />
            </div>
          );
        })}
      </div>
    );
  };

  const CommentTextArea = () => {
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const fileInputRef = useRef(null);
    const [content, setContent] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const fullname = user_id == quoteData?.user_id ? 'You' : quoteData?.user_name;
    const handleUploadFile = (event) => {
      const files = Array.from(event.target.files);
      setUploadedFiles((prevFiles) => {
        const newFiles = Array.from(files);
        const uniqueFiles = newFiles.filter((file) => !prevFiles.some((existingFile) => existingFile.name === file.name));
        return [...prevFiles, ...uniqueFiles];
      });
    };
    const handleRemoveFile = (index) => {
      setUploadedFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    };

    const truncateText = (text, wordLimit = 200) => {
      if (!text) return '';
      const words = text.split(' ');
      return words.length > wordLimit ? words.slice(0, wordLimit).join(' ') + '...' : text;
    };
    const handleSubmit = () => {
      setIsSubmitting(true);
      const formData = new FormData();
      formData.append('content', content);
      formData.append('psow_id', reportData?.psow_id);
      formData.append('date_range_start', startDate);
      formData.append('date_range_end', endDate);
      formData.append('comment_type_id', convertedType);
      formData.append('quote_id', selectedComment !== replyParentID ? selectedComment : '');
      uploadedFiles.forEach((file) => {
        formData.append('attachment_files', file);
      });
      formData.append('parent_id', replyParentID || '');
      AddComment(formData)
        .then((res) => {
          if (res.data.status === 200) {
            if (replyParentID) {
              fetchReplies({ commentID: replyParentID, toggle: false });
            }
            fetchCommentData();
            setContent('');
            setShowQuote(false);
            setUploadedFiles([]);
            setReplyParentID(null);
          }
        })
        .finally(() => setIsSubmitting(false));
    };
    const handleInputComment = (e) => {
      setContent(e.target.value);
    };
    const handleCloseQuote = () => {
      setShowQuote(false);
      setReplyParentID(null);
    };

    return (
      <>
        {uploadedFiles.length > 0 && (
          <div className="d-flex flex-wrap mb-2">
            {uploadedFiles.map((file, index) => (
              <div key={index} className="position-relative d-flex flex-column align-items-center m-1">
                <FaFile size={40} />
                <span className="small text-center text-truncate" style={{ maxWidth: '60px' }}>
                  {file.name}
                </span>
                <button
                  onClick={() => handleRemoveFile(index)}
                  className="btn btn-sm btn-light position-absolute"
                  style={{
                    top: '-5px',
                    right: '-5px',
                    borderRadius: '50%',
                    fontSize: '10px',
                    width: '16px',
                    height: '16px',
                    padding: '0',
                    lineHeight: '1',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    border: '1px solid #ccc',
                  }}
                >
                  ✕
                </button>
              </div>
            ))}
          </div>
        )}
        {showQuote && (
          <div className="d-flex" style={{ width: '90.75%' }}>
            <div className="px-3 pb-3 pt-1 bg-light shadow-sm flex-grow-1" style={{ borderRadius: '20px 20px 0 0' }}>
              <div className="d-flex justify-content-between align-items-center">
                <div className="font-weight-bold" style={{ fontSize: '12px' }}>
                  {fullname}
                </div>
                <CTooltip content="Remove quoted message">
                  <button
                    className="border-0 bg-transparent"
                    style={{
                      fontSize: '18px',
                      color: '#6c757d',
                      cursor: 'pointer',
                    }}
                    onClick={handleCloseQuote}
                  >
                    ✖
                  </button>
                </CTooltip>
              </div>
              <small className="d-block">{truncateText(quoteData?.content)}</small>
            </div>
          </div>
        )}

        <div className="d-flex">
          <textarea
            className="form-control shadow-sm flex-grow-1"
            placeholder="Add a comment..."
            ref={replyInputRef}
            rows="3"
            style={{
              overflowY: 'auto',
              borderRadius: showQuote ? '0 0 5px 5px' : '5px',
              paddingRight: '60px',
            }}
            onChange={handleInputComment}
          ></textarea>

          <div className="d-flex align-items-center px-2">
            <CInput type="file" multiple accept=".jpg,.jpeg,.png,.pdf,.docx,.xlsx,.mp4,.webm,.avi,.mov,.mkv,.flv,.3gp,.ogv,.wmv" onChange={handleUploadFile} style={{ display: 'none' }} innerRef={fileInputRef} />
            <CTooltip content="Upload a file">
              <MdUploadFile role="button" onClick={() => fileInputRef.current.click()} style={{ fontSize: '25px', color: '#6c757d', marginRight: '8px', cursor: 'pointer' }} />
            </CTooltip>
            {isSubmitting ? <div className="spinner-border text-black" style={{ width: '24px', height: '24px' }} role="status"></div> : <IoSend role="button" style={{ fontSize: '22px', color: '#6c757d', cursor: 'pointer' }} onClick={handleSubmit} />}
          </div>
        </div>
      </>
    );
  };

  const AttachmentView = ({ attachmentData, isEditing, onRemoveAttachment }) => {
    const [showModal, setShowModal] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [updatedAttachments, setUpdatedAttachments] = useState(attachmentData);
    const [attachmentType, setAttachmentType] = useState(null);
    const attachmentIcons = {
      1: { component: FaFileImage, color: '#0052CC', clickable: true },
      2: { component: FaFilePdf, color: '#D32F2F', clickable: true },
      3: { component: FaFileExcel, color: '#107c41', clickable: true },
      4: { component: FaPhotoVideo, color: '#0052CC', clickable: true },
    };
    const downloadFile = (fileUrl) => {
      const link = document.createElement('a');
      link.href = fileUrl;
      link.target = '_blank';
      link.rel = 'noopener noreferrer';

      const filename = decodeURIComponent(fileUrl.split('/').pop()) || 'downloaded_file';
      link.setAttribute('download', filename);

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };

    const handleAttachmentView = (type_id, fileUrl) => {
      if ([1, 4].includes(type_id)) {
        setAttachmentType(type_id);
        setSelectedImage(fileUrl);
        setShowModal(true);
      } else if ([2, 3].includes(type_id)) {
        downloadFile(fileUrl);
      }
    };
    const handleRemove = (id) => {
      const filteredAttachments = updatedAttachments.filter((item) => item.id !== id);
      setUpdatedAttachments(filteredAttachments);
      onRemoveAttachment(id);
    };
    const MediaModal = ({ show, imageUrl, attachmentType, onClose }) => {
      const [loading, setLoading] = useState(true);
      if (!show || !imageUrl) return null;

      return (
        <>
          <div className="modal show d-flex align-items-center justify-content-center position-fixed w-100 h-100" tabIndex="-1" role="dialog" style={{ zIndex: 1050, backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
            <div
              className="modal-dialog d-flex align-items-center justify-content-center"
              role="document"
              style={{
                maxWidth: '90vw',
                maxHeight: '90vh',
              }}
            >
              <div className="modal-content border-0 bg-transparent d-flex align-items-center justify-content-center position-relative">
                <button
                  type="button"
                  className="btn btn-outline-light rounded-circle"
                  style={{
                    width: '40px',
                    height: '40px',
                    fontSize: '1.5rem',
                    backgroundColor: 'rgba(255, 255, 255, 0.3)',
                    border: 'none',
                    color: 'white',
                    backdropFilter: 'blur(8px)',
                    position: 'absolute',
                    top: '10px',
                    right: '15px',
                    zIndex: 1060,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    transition: '0.3s',
                    cursor: 'pointer',
                  }}
                  onClick={onClose}
                  aria-label="Close"
                >
                  ✕
                </button>

                <div
                  className="modal-body d-flex align-items-center justify-content-center"
                  style={{
                    width: '100vw',
                    height: '100vh',
                  }}
                >
                  {attachmentType === 4 ? (
                    <video
                      controls
                      className="img-fluid"
                      style={{
                        borderRadius: '12px',
                        maxHeight: '85vh',
                        maxWidth: '80vw',
                        objectFit: 'contain',
                        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)',
                      }}
                    >
                      <source src={imageUrl} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  ) : (
                    <>
                      {loading && (
                        <div
                          className="spinner-border text-white"
                          style={{
                            width: '120px',
                            height: '120px',
                            position: 'absolute',
                          }}
                          role="status"
                        ></div>
                      )}

                      <img
                        src={imageUrl}
                        alt="Attachment Preview"
                        className="img-fluid"
                        style={{
                          borderRadius: '12px',
                          maxHeight: '85vh',
                          maxWidth: '80vw',
                          objectFit: 'contain',
                          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)',
                          visibility: loading ? 'hidden' : 'visible',
                          opacity: loading ? 0 : 1,
                          transition: 'opacity 0.3s ease-in-out',
                        }}
                        onLoad={() => setLoading(false)}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="modal-backdrop show position-fixed w-100 h-100" style={{ zIndex: 1040, backgroundColor: 'rgba(0, 0, 0, 0.5)' }} onClick={onClose}></div>
        </>
      );
    };
    const FilePreview = ({ item, handleAttachmentView }) => {
      const attachment = attachmentIcons[item.attachment_type_id];

      const filePreviewStyle = {
        width: '100px',
        height: '80px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#ffffff',
        borderRadius: '5px',
        border: '1px solid #dfe1e6',
        boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)',
      };
      if (!attachment) return null;

      const IconComponent = attachment.component;

      return (
        <div className="file-preview d-flex flex-column align-items-center" style={filePreviewStyle}>
          <IconComponent
            size={attachment.clickable ? 50 : 40}
            style={{ color: attachment.color, cursor: attachment.clickable ? 'pointer' : 'default' }}
            onClick={attachment.clickable ? () => handleAttachmentView(item.attachment_type_id, item.url) : undefined}
            role={attachment.clickable ? 'button' : undefined}
            onMouseOver={(e) => attachment.clickable && (e.target.style.opacity = 0.4)}
            onMouseOut={(e) => attachment.clickable && (e.target.style.opacity = 1)}
          />
        </div>
      );
    };
    const isScrollable = attachmentData.length > 1;

    return (
      <>
        <span className="font-weight-bold d-block">Attachment</span>
        <div
          className="attachment-container mt-2 p-2"
          style={{
            display: 'flex',
            flexDirection: isScrollable ? 'row' : 'column',
            overflowX: isScrollable ? 'auto' : 'hidden',
            gap: '4px',
            maxWidth: '100%',
            whiteSpace: 'nowrap',
            backgroundColor: '#f4f5f7',
            borderRadius: '5px',
            border: '1px solid #dfe1e6',
          }}
        >
          {attachmentData.map((item) => (
            <div
              key={item.id}
              className="d-flex flex-column align-items-center attachment-item position-relative"
              style={{
                minWidth: isScrollable ? '100px' : 'unset',
                textAlign: 'center',
                padding: '8px',
                borderRadius: '5px',
                backgroundColor: item.attachment_type_id === 2 ? '#ffffff' : 'transparent',
                border: item.attachment_type_id === 2 ? '1px solid #dfe1e6' : 'none',
                boxShadow: item.attachment_type_id === 2 ? '0px 1px 3px rgba(0, 0, 0, 0.1)' : 'none',
              }}
            >
              <FilePreview item={item} handleAttachmentView={handleAttachmentView} />
              <span
                className="text-center"
                style={{
                  textOverflow: 'ellipsis',
                  maxWidth: '80px',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  fontSize: '11px',
                  color: '#42526e',
                  marginTop: '5px',
                }}
              >
                {item.filename}
              </span>
              {isEditing && (
                <button
                  className="border-0 bg-aimhi-textlight-neutral position-absolute"
                  style={{
                    fontSize: '18px',
                    color: '#fff',
                    cursor: 'pointer',
                    top: '-5px',
                    right: '-5px',
                    borderRadius: '50%',
                    width: '20px',
                    height: '20px',
                    fontSize: '12px',
                    lineHeight: '12px',
                    padding: 0,
                  }}
                  onClick={() => handleRemove(item.id)}
                >
                  ✖
                </button>
              )}
            </div>
          ))}
        </div>
        <MediaModal show={showModal} imageUrl={selectedImage} attachmentType={attachmentType} onClose={() => setShowModal(false)} />
      </>
    );
  };

  const onMount = isModalOpen === true && isInit.current;
  if (onMount) {
    isInit.current = false;
    fetchCommentData();
  }

  return (
    <CModal show={isModalOpen} onClose={onClose} size="lg" keyboard={false} closeOnBackdrop={false}>
      <CModalHeader closeButton>{title}</CModalHeader>
      <CModalBody>
        <div className="chat-container d-flex flex-column gap-3 overflow-auto flex-grow-1" style={{ maxHeight: '475px' }}>
          {isLoading ? (
            <>
              <ChatMessageSkeleton align="start" />
              <ChatMessageSkeleton align="end" />
            </>
          ) : (
            messages.length > 0 && messages.map((msg) => <ChatMessage key={msg.id} message={msg} isReply={false} />)
          )}
        </div>

        {type != 2 && (
          <div className="chat-input-container mt-3 pt-3 position-relative">
            <CommentTextArea />
          </div>
        )}
      </CModalBody>
    </CModal>
  );
};

export default ModalComments;
