export const preventInvalidKeys = (evt) => {
  if (["e", "E", "+", "-"].includes(evt.key)) {
    evt.preventDefault();
  }
};

export const blurOnWheel = (e) => {
  e.target.blur();
};

export const preventInvalidPaste = (evt) => {
  const pastedData = evt.clipboardData.getData("text");
  if (/[eE+-]/.test(pastedData)) {
    evt.preventDefault();
  }
};