import $axios from 'src/utils/axios';
import IP from 'src/StoreGlobal/IP';

let companyId = parseInt(localStorage.getItem('company_id'));
let accessToken = localStorage.getItem('token');
let user_id = parseInt(localStorage.getItem('user_id'));

export const getReport = async (projectId, reportType, status) => {
  let params = {
    companyId: parseInt(localStorage.getItem('company_id')),
    accessToken: localStorage.getItem('token'),
    userId: parseInt(localStorage.getItem('user_id')),
  };
  return await $axios.get(`report/${params.companyId}/${projectId}/${reportType}?archived=${status}`);
};

//save backup

export const saveDilyReport = async (dataReport, type, project_id) => {
  let data = {
    company_id: companyId,
    project_id: project_id,
    user_id: user_id,
    type: type,
    data: dataReport,
  };
  console.log(data);

  return await $axios.post('report/add', {
    ...data,
  });
};

export const saveSwa = async (file, swa, project_id) => {
  let formData = new FormData();
  let data = {
    company_id: companyId,
    project_id: project_id,
    user_id: user_id,
    prepared_by: swa[0].prepared_by,
    billing_percentage: swa[0].billing_percentage,
    remarks: swa[0].remarks,
  };

  formData.append('data', JSON.stringify(data));
  for (let i = 0; i < file.length; i++) {
    formData.append(`files`, file[i]);
  }

  return await $axios.post('report/add/swa', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const EditSwa = async (file, swa, project_id, removeFile) => {
  let formData = new FormData();
  let data = {
    company_id: companyId,
    project_id: project_id,
    user_id: user_id,
    report_id: swa[0].report_id,
    prepared_by: swa[0].prepared_by,
    billing_percentage: swa[0].billing_percentage,
    remarks: swa[0].remarks,
    remove: removeFile,
  };

  formData.append('data', JSON.stringify(data));
  for (let i = 0; i < file.length; i++) {
    formData.append(`files`, file[i]);
  }

  return await $axios.put('report/edit/swa', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const getDetails = async (reportType, reporrtLogId) => {
  return await $axios.get(`report/details/${reportType}/${reporrtLogId}`);
};

export const editReport = async (dataReport, reportType) => {
  let data = {
    data: dataReport,
  };
  return await $axios.put(`report/edit/${reportType}`, {
    ...data,
  });
};

export const changeStatus = async (report_log_id, method) => {
  return await $axios[method.toLowerCase()](`report/archive/${report_log_id}`);
};

export const saveEquipmentUsage = (equipmentData, project_id, user_id) => {
  let data = {
    company_id: parseInt(localStorage.getItem('company_id')),
    project_id: parseInt(project_id),
    user_id: parseInt(localStorage.getItem('user_id')),
    data: equipmentData,
  };

  return $axios.post('equipment/usage', {
    ...data,
  });
};

export const getEquipmentUsage = async (projectId, status) => {
  return await $axios.get(`equipment/usage?company=${companyId}&project=${projectId}&archive=${status}`);
};

export const getByGroupId = async (groupId) => {
  return await $axios.get(`equipment/usage/${groupId}`);
};

export const EditEquipmentUsage = (equepmentdata, groupId, userId) => {
  let data = {
    user_id: userId,
    data: equepmentdata,
  };

  return $axios.put(`equipment/usage/${groupId}`, {
    ...data,
  });
};

export const archiveEquipmentUsage = (groupId) => {
  return $axios.delete(`equipment/usage/${groupId}?archive=1`);
};

export const restoreEquipmentUsage = (groupId) => {
  return $axios.put(`equipment/usage/${groupId}?archive=1`);
};

export const getSowlistWeekly = async (projectId) => {
  return await $axios.get(`report/weekly/sow-list/${projectId}`);
};

export const saveWeekly = (project_id, item) => {
  let data = {
    project_id: project_id,
    data: item,
  };

  return $axios.post('report/weekly/save', {
    ...data,
  });
};

export const getTwoWeeksFilter = async (projectId) => {
  return await $axios.get(`report/weekly/lookahead/${projectId}/dates `);
};

export const getTwoWeeksData = async (projectId, start, end) => {
  console.log(projectId, start, end);

  return await $axios.get(`report/weekly/lookahead/${projectId}/reports?start=${start}&end=${end} `);
};

export const AddComment = (formData) => {
  return $axios.post(`report/comment/add`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};


export const UpdateComment = (formData) => {
  return $axios.post(`report/comment/update`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const DeleteComment = (data) => {
  return $axios.post(`report/comment/delete`, data);
};

export const GetComment = (psow_id, comment_type_id = 1, start_date, end_date) => {
  return $axios.get(`report/comment/fetch?psow_id=${psow_id}&comment_type_id=${comment_type_id}&start_date=${start_date}&end_date=${end_date}`);
};

export const GetReply = (comment_id) => {
  return $axios.get(`report/comment/replies?comment_id=${comment_id}`);
};

export const GetHistoricalComment = (psow_id, start_date, end_date) => {
  return $axios.get(`report/historical/fetch?psow_id=${psow_id}&start_date=${start_date}&end_date=${end_date}`)
}