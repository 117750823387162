
import { CCol } from "@coreui/react";
import "./widgets.css";
import Lottie from "react-lottie";
import LoadingLottie from "../../json/loading_new";
// import LoadingLottie from "../../json/loadingTest2";
const Loading = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: LoadingLottie,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <>
      {/* <CCol xs="12" sm="12" md="12" lg="12">
        <div className="loading-final">
          <div className="loading-lottie-final">
            <Lottie options={defaultOptions} height={300} width={300} />
          </div>
        </div>
      </CCol> */}
      <CCol xs="12" sm="12" md="12" lg="12">
        <div className="loading-final">
          <div className="loading-lottie-final">
            {/* <Lottie options={defaultOptions} height={300} width={300} /> */}
            <Lottie options={defaultOptions} />
            <div>
              <div style={{ fontWeight: "700", fontSize: "20px", marginTop: -90 }}>
                Loading...
              </div>
            </div>
            {/* <div class="typewriter">
              <h1>Mobilizing...hang tight!</h1>
            </div> */}
          </div>
        </div>
      </CCol>
    </>
  );
};

export default Loading;
